/* ------------------------------------------------------------ *\
	Nav-inner
\* ------------------------------------------------------------ */

.nav-inner {
  margin-bottom: 10rem;
  background-color: $c-blue;
  color: $c-white;
  padding: 4.3rem 0 4rem;

  @include breakpoint-down(mobile) {
    margin-bottom: 5rem;
  }

  .nav__title {
    color: $c-white;
    text-align: center;
    margin-bottom: 1.7rem;

    @include breakpoint-down(mobile-medium) {
      text-align: left;
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint-down(small-desktop) {
      justify-content: flex-start;
    }
  }

  ul a {
    font-size: 2rem;
    font-weight: 500;
    text-transform: capitalize;

    @include breakpoint-down(mobile) {
      display: block;
      position: relative;
      padding-right: 2rem;
      font-size: 1.8rem;
    }

    span {
      text-transform: none;
    }
  }

  ul a svg {
    @include breakpoint-down(mobile) {
      position: absolute;
      right: 0;
      top: .7rem;
    }
  }

  li {
    padding-right: 2.24rem;
    margin: 0 3.24rem;

    @include breakpoint-down(large-desktop) {
      margin: 0 1.24rem;
    }

    @include breakpoint-down(small-desktop) {
      flex: 0 0 calc(33.33% - 2.48rem);
      margin: 0 1.24rem 1.7rem 1.24rem;
    }

    @include breakpoint-down(tablet) {
      flex: 0 0 50%;
    }

    @include breakpoint-down(mobile) {
      flex: 0 0 100%;
      margin: 0 1.24rem 1.7rem 0;
    }
  }
}
