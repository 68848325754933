/* ------------------------------------------------------------ *\
	Tribe Events
\* ------------------------------------------------------------ */

#tribe-events-pg-template {
  max-width: unset !important;
  padding: 0 !important;
}

.yam-events-hero-extra-mt {
  margin-top: 180px !important;

  @include breakpoint-down(mobile) {
    margin-top: 124px !important;
  }
}

.tribe_venue-template-default {
  padding-top: 180px;

  @include breakpoint-down(mobile) {
    padding-top: 124px;
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-l-container {
  padding-top: 1rem !important;
}

.tribe-events .tribe-events-calendar-month__multiday-event-bar-inner {
  background-color: $c-grey3 !important;
}

.tribe-events .tribe-events-c-ical__link,
.tribe-events .tribe-events-c-ical__link:visited {
  background-color: #fff !important;
  border: 1px solid $c-blue !important;
  color: $c-blue !important;
}

.tribe-events .tribe-events-c-ical__link:active,
.tribe-events .tribe-events-c-ical__link:focus,
.tribe-events .tribe-events-c-ical__link:hover {
  background-color: $c-blue !important;
  color: #fff !important;
}

.tribe-events .tribe-events-calendar-list__event-title-link:active,
.tribe-events .tribe-events-calendar-list__event-title-link:focus,
.tribe-events .tribe-events-calendar-list__event-title-link:hover {
  color: $c-grey !important;
}

.tribe-events-container {
  padding-left: var(--tec-grid-gutter-page) !important;
  padding-right: var(--tec-grid-gutter-page) !important;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--tec-grid-width);
  width: 100%;

  @include breakpoint-down(mobile) {
    max-width: var(--tec-grid-width) !important;
    padding-left: var(--tec-grid-gutter-page-small) !important;
    padding-right: var(--tec-grid-gutter-page-small) !important;
  }
}

.tribe-events .tribe-events-l-container {
  @include breakpoint-down(mobile) {
    padding-top: 1rem !important;
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-breadcrumbs,
.tribe-events-header__breadcrumbs .tribe-events-c-breadcrumbs,
.tribe-events-c-breadcrumbs {
  margin-bottom: 2rem !important;
}

.tribe-events-c-breadcrumbs__list-item-icon-svg {
  height: 16px !important;
  margin: 0 10px !important;
  width: 10px !important;

  @include breakpoint-down(mobile) {
    height: 10px !important;
    width: 6px !important;
  }
}

.tribe-events .tribe-events-c-breadcrumbs__list,
.tribe-events-c-breadcrumbs__list {
  font-family: var(--tec-font-family-base) !important;
  font-size: 24px !important;
  line-height: var(--tec-line-height-1) !important;

  @include breakpoint-down(mobile) {
    font-size: 16px !important;
  }
}

.tribe-events .tribe-events-c-breadcrumbs__list-item,
.tribe-events-c-breadcrumbs__list-item {
  align-items: center !important;
  display: inline-flex !important;
  color: $c-blue !important;
}

a.tribe-common-c-btn-border-small.tribe-events-c-top-bar__today-button.tribe-common-a11y-hidden {
  border: thin solid $c-blue;
  color: $c-blue;
}

.tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus-within {
  background: $c-blue !important;
}

.tribe-common-c-btn-border:hover svg.tribe-common-c-svgicon.tribe-common-c-svgicon--caret-down.tribe-events-c-subscribe-dropdown__button-icon,
.tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus-within svg.tribe-common-c-svgicon.tribe-common-c-svgicon--caret-down.tribe-events-c-subscribe-dropdown__button-icon {
  color: $c-white !important;
}

.tribe-common-c-btn-border,
.tribe-common-c-btn-border:focus,
.tribe-common-c-btn-border:focus-within {
  border: thin solid $c-blue !important;
  color: $c-blue !important;
}

.tribe-common-c-btn-border:hover,
.tribe-common-c-btn-border:focus,
.tribe-common-c-btn-border:focus-within {
  color: $c-white !important;
}

.tribe-events .datepicker .day.active,
.tribe-events .datepicker .day.active.focused,
.tribe-events .datepicker .day.active:focus,
.tribe-events .datepicker .day.active:hover,
.tribe-events .datepicker .month.active,
.tribe-events .datepicker .month.active.focused,
.tribe-events .datepicker .month.active:focus,
.tribe-events .datepicker .month.active:hover,
.tribe-events .datepicker .year.active,
.tribe-events .datepicker .year.active.focused,
.tribe-events .datepicker .year.active:focus,
.tribe-events .datepicker .year.active:hover,
.tribe-common-c-btn-border:hover {
  background: $c-blue !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__day:hover:after,
.tribe-events .tribe-events-calendar-month__day-cell--selected,
.tribe-events .tribe-events-calendar-month__day-cell--selected:focus,
.tribe-events .tribe-events-calendar-month__day-cell--selected:hover,
.tribe-events .tribe-events-calendar-month__mobile-events-icon--event {
  background-color: $c-blue !important;
}

.tribe-common a,
.tribe-common a:active,
.tribe-common a:focus,
.tribe-common a:hover,
.tribe-common a:visited,
.tribe-common .tribe-common-h5,
.tribe-common .tribe-common-h6,
.tribe-common .tribe-common-c-svgicon {
  color: $c-blue !important;
  font-family: $ff-montserrat !important;
}

.tribe-events-calendar-month__day.tribe-events-calendar-month__day--current {
  time.tribe-events-calendar-month__day-date-daynum {
    position: relative;

    @include breakpoint-down(mobile) {
      color: $c-grey3 !important;
    }

    &:before {
      content: "";
      border-radius: 50%;
      border: 0.1rem solid $c-grey3;
      width: 5.4rem;
      height: 5.4rem;
      display: block;
      opacity: 0.7;
      position: absolute;
      top: -1.1rem;
      left: -1.7rem;

      @include breakpoint-down(mobile) {
        width: 3rem;
        height: 3rem;
        top: -0.24rem;
        left: -1.124rem;
      }
    }
  }
}

.tribe-common .tribe-common-h3,
.tribe-common .tribe-common-b2,
.tribe-common .tribe-common-b3,
.tribe-common .tribe-common-h--alt,
time.tribe-events-calendar-month__day-date-daynum {
  color: $c-grey !important;
  font-family: $ff-montserrat !important;
}

h3.tribe-events-calendar-month__header-column-title.tribe-common-b3 {
  @include breakpoint-down(mobile) {
    color: $c-blue !important;
  }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__header-column-title {
  text-align: center !important;
  font-family: $ff-montserrat !important;
}

.tribe-events .tribe-events-calendar-month__calendar-event-datetime,
.tribe-common--breakpoint-medium.tribe-common .tribe-common-h4 {
  font-family: $ff-montserrat !important;
}

.tribe-events .tribe-events-c-breadcrumbs__list-item-link,
.tribe-common a.tribe-events-c-breadcrumbs__list-item-link,
a.tribe-events-c-breadcrumbs__list-item-link {
  color: $c-grey !important;
  text-decoration: none;
}

button.tribe-events-calendar-month__day-cell.tribe-events-calendar-month__day-cell--mobile.tribe-events-calendar-month__day-cell--selected .tribe-events-calendar-month__day-date-daynum {
  color: $c-white !important;
}

.tribe-events .tribe-events-c-search__button {
  background-color: $c-blue !important;
}