/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */

.select {
  @include breakpoint-down(mobile) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
