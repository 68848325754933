/* ------------------------------------------------------------ *\
	Content-article
\* ------------------------------------------------------------ */

.content-article {
	max-width: 83rem; 
	padding: 0 2rem 0 5rem; 

	@include breakpoint-down(tablet) {
		padding: 0; 
	}
}
