/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative;
	min-height: 100vh;

	@include breakpoint-down(mobile) {
		overflow: hidden; 
	}
}

.wrapper.toggle-nav {
	width: 100%; 

	.header__nav {
		transform: translateX(0); 
	}

	.logo__primary {
		opacity: 1; 
	}

	.logo__secondary {
		opacity: 0; 
	}

	.header__logo {
		margin-left: 2.2rem; 
	}

	.burger {
		span {
			background-color: $c-blue; 
		}

		span:nth-child(1) {
			transform: translateY(.9rem) rotate(-45deg); 
		}

		span:nth-child(2) {
			opacity: 0; 
		}

		span:nth-child(3) {
			transform: translateY(-.9rem) rotate(45deg); 
		}
	}
}
