/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	margin-bottom: 9.5rem; 

	@include breakpoint-down(tablet) {
		margin-bottom: 5rem; 
	}

	.accordion__section.is-active .accordion__body {
		display: block; 
	}

	.accordion__section.is-active .accordion__icon {
		transform: rotate(180deg); 
	}

	.accordion__section {
		border-bottom: .1rem solid $c-white; 
	}

	.accordion__head {
		background-color: $c-blue; 
		padding: 1rem 7rem 1rem 2.7rem; 
		position: relative; 
		cursor: pointer;

		h4 {
			color: $c-white;
			text-transform: capitalize; 
			margin-bottom: 0; 
		}

		h4 span {
			text-transform: none; 
		}
	}

	.accordion__icon {
		color: $c-white; 
		font-size: 1.8rem; 
		position: absolute; 
		top: 1.4rem; 
		right: 4rem; 
		transition: transform $t-duration $animation; 

		@include breakpoint-down(mobile) {
			top: .9rem; 
		}

		svg {
			vertical-align: middle; 
		}
	}

	.accordion__body {
		display: none; 
		padding: 3.4rem 6.1rem 3.8rem; 

		@include breakpoint-down(tablet) {
			padding: 3.4rem 3rem; 
		}

		@include breakpoint-down(mobile-medium) {
			padding: 2.5rem 1rem; 
		}
	}

	.accordion__body ul {
		list-style: none; 
	}

	.accordion__body li + li {
		margin-top: .4rem;
	}

	.accordion__body li {
		padding-left: 2.5rem; 
		position: relative; 

		&:before {
			content: '';
			width: .8rem; 
			height: .8rem; 
			border-radius: 50%; 
			background-color: $c-blue; 
			position: absolute; 
			top: .8rem; 
			left: .1rem; 
		}
	}

	.accordion__body h5 {
		margin: 3.1rem 0.3rem 0.5rem; 
	}
}
