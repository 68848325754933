/* ------------------------------------------------------------ *\
	Section-article
\* ------------------------------------------------------------ */

.section-article {
	padding: 18.3rem 0 8.9rem; 
	overflow: hidden; 

	@include breakpoint-down(tablet) {
		padding: 15rem 0 6rem; 
	}

	.section__inner {
		position: relative; 
		display: flex; 
		justify-content: space-between; 
		padding-right: 35rem; 
		transition: padding-right $t-duration $animation; 

		@include breakpoint-down(tablet) {
			padding-right: 0; 
		}
	}

	.section__entry {
		padding-left: 5rem; 

		@include breakpoint-down(mobile) {
			padding-left: 0; 
		}
	}

	.section__title {
		margin-bottom: 1.4rem; 
	}

	.section__meta {
		font-size: 2rem; 
		font-weight: 500; 
		color: $c-black; 
		display: block; 
		margin-bottom: 4.4rem; 
	}
}
