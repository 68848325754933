/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
  padding: 2.3rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  transition: background-color $t-duration $animation,
  transform $t-duration $animation;

  @include breakpoint-down(mobile) {
    padding: 3rem 0;
  }

  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__nav {
    @include breakpoint-down(mobile) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      left: 2rem;
      height: calc(100vh - 8.7rem);
      overflow: scroll;
      background-color: $c-white;
      transform: translateX(100%);
      transition: transform $t-duration $animation;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: none;
      background-color: $c-white;
      height: 8.3rem;

      @include breakpoint-down(mobile) {
        display: block;
      }
    }
  }

  .header__logo {
    margin-top: 1.1rem;
    transition: margin-left $t-duration $animation;

    @include breakpoint-down(mobile) {
      position: relative;
      z-index: 2;
      margin-top: 0;
      max-width: 7.5rem;
    }
  }

  .header__burger {
    position: absolute;
    display: none;

    @include breakpoint-down(mobile) {
      position: relative;
      display: block;
    }
  }
}

/*  Header--alt  */

.header--alt {
  background-color: $c-white;
  box-shadow: 0 .5rem 2rem 0 rgba($c-black, .13);

  .logo .logo__secondary {
    opacity: 0;
  }

  .logo .logo__primary {
    opacity: 1;
  }

  .nav {
    color: $c-blue;

    & > ul a:after {
      background-color: $c-blue;
    }

    a {
      color: $c-blue;

      @include breakpoint-down(mobile) {
        color: $c-base;
      }
    }
  }

  .nav--sub {
    a {
      @include breakpoint-down(mobile) {
        color: $c-blue;
      }
    }
  }

  .socials {
    a {
      border: .1rem solid $c-blue;
    }

    a:focus,
    a:hover {
      color: $c-white;
      background-color: $c-blue;
    }
  }

  .burger {
    span {
      background-color: $c-blue;
    }
  }
}


.header.is-hidden {
  transform: translateY(-120%);
}
