/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	position: relative; 
	display: inline-block; 

	.logo__primary,
	.logo__secondary {
		transition: opacity $t-duration $animation; 
	}

	.logo__primary {
		position: absolute; 
		left: 0; 
		opacity: 0; 
	}
}
