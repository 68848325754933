/* ------------------------------------------------------------ *\
	List-cards
\* ------------------------------------------------------------ */

.list-cards {
	ul {
		list-style: none; 
	}

	li + li {
		margin-top: 3.5rem; 
	}
}
