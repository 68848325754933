/* ------------------------------------------------------------ *\
	Media
\* ------------------------------------------------------------ */

.media {
	position: relative; 

	.media__link {
		position: absolute; 
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($c-black, .35); 
		font-size: 6.2rem; 
		color: $c-white; 
		display: flex; 
		align-items: center; 
		justify-content: center; 
		transition: background-color $t-duration $animation; 

		&:focus,
		&:hover {
			background-color: rgba($c-black, .2); 
		}

		&:focus svg,
		&:hover svg {
			opacity: 1; 
		}

		svg {
			opacity: .85; 
			transition: opacity $t-duration $animation; 
		}
	}
}
