/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
	background-color: $c-blue;
	border-radius: .8rem;
	border: .1rem solid $c-blue;
	font-size: 1.5rem;
	font-weight: 500;
	color: $c-white;
	display: inline-block;
	text-decoration: none;
	padding: 0.7rem 3.1rem;
	text-align: center;
	text-transform: capitalize;
	transition:
		color $t-duration $animation,
		background-color $t-duration $animation,
		border-color $t-duration $animation;

	@include breakpoint-down(mobile-medium) {
		display: block;
	}

	&:focus,
	&:hover {
		color: $c-blue;
		border-color: $c-blue;
		background-color: $c-white;
	}

	span {
		text-transform: none;
	}
}

/*  btn--small  */

.btn--small {
	padding: 1.1rem 4rem 1.1rem;
}

/*  btn--alt  */

.btn--alt {
	padding: 0.7rem 4.2rem;
}

/*  btn--big  */

.btn--big {
	padding: 0.7rem 4.9rem;
	min-width: 21.5rem;

	@include breakpoint-down(mobile-medium) {
		min-width: 100%;
	}
}

/*  btn--transparent  */

.btn--transparent {
	background-color: transparent;
	color: $c-blue;

	&:focus,
	&:hover {
		background-color: $c-blue;
		color: $c-white;
	}
}
