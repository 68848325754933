/* ------------------------------------------------------------ *\
	Social-images-grid
\* ------------------------------------------------------------ */

.social-images-grid {
	margin-bottom: 5rem; 

	.images-grid__head {
		padding-left: 5rem; 
		margin-bottom: 3.6rem; 

		@include breakpoint-down(mobile) {
			padding-left: 0; 
		}
	}

	.images-grid__col img {
		width: 100%;
		height: 100%; 
		object-fit: cover;
	}
}
