/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar {
	max-width: 35rem; 
	background-color: $c-blue-light; 
	border-radius: 1.6rem 1.6rem 0 0;
	padding: 3.2rem 2.8rem 3.5rem 3.7rem; 
	position: absolute; 
	top: 0; 
	right: 0; 
	transition: 
		box-shadow $t-duration $animation,
		transform $t-duration $animation; 

	@include breakpoint-down(tablet) {
		transform: translateX(100%); 
		margin-right: -2rem; 
		border-radius: 1.6rem 0 0 1.6rem;
	}



	@include breakpoint-down(mobile-medium) {
		padding: 3.2rem 1.2rem 3.5rem 1.8rem; 
		max-width: 27.5rem; 
	}

	.sidebar__head {
		padding: 0 .3rem; 
		margin-bottom: 7.3rem; 

		@include breakpoint-down(mobile) {
			padding: 0; 
		}
	}

	.sidebar__head .sidebar__title {
		margin-bottom: 2rem; 
		padding-bottom: 1.1rem; 
		position: relative; 

		&:after {
			content: '';
			position: absolute; 
			bottom: 0; 
			left: -.8rem; 
			right: .1rem; 
			background-color: $c-grey3; 
			height: .1rem; 
			display: block; 

			@include breakpoint-down(mobile) {
				left: 0; 
				right: 0; 
			}
		}
	}

	.sidebar__entry {
		padding: 0 .3rem; 
		margin-bottom: 2.9rem; 

		@include breakpoint-down(mobile) {
			padding: 0; 
		}
	}

	.sidebar__body .sidebar__title {
		margin-bottom: 1.2rem; 
	}

	.sidebar__close {
		position: absolute; 
		display: none; 
		justify-content: center; 
		align-items: center; 
		top: 3.2rem; 
		right: 100%; 
		width: 4rem; 
		height: 4rem; 
		background-color: inherit; 
		border-radius: 1.6rem 0 0 1.6rem;
		box-shadow: -.3rem 0 .5rem -.2rem rgba(0, 0, 0, .2);

		@include breakpoint-down(tablet) {
			display: flex; 
		}

		&:before,
		&:after {
			position: absolute; 
			top: 50%; 
			left: 50%; 
			content: ''; 
			width: 1.2rem; 
			height: .2rem; 
			background-color: $c-blue; 
			display: block; 
			transition: 
				width $t-duration $animation, 
				transform $t-duration $animation; 
		}

		&:before {
			transform: translate(-50%, 200%) rotate(45deg); 
		}

		&:after {
			transform: translate(-50%, -200%) rotate(-45deg); 
		}
	}
}

.sidebar.is-shown {
	box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, .3);
	transform: translateX(0); 

	.sidebar__close {
		&:before {
			width: 2rem; 
			transform: translate(-50%, 0) rotate(45deg); 
		}

		&:after {
			width: 2rem; 
			transform: translate(-50%, 0) rotate(-45deg); 
		}
	}
}
