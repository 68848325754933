/* ------------------------------------------------------------ *\
	Blog Archive
\* ------------------------------------------------------------ */

#blog-archive {
  padding-bottom: 5rem;
}

#blog-archive .cta {
  margin-bottom: 4rem;
}

#blog-archive .slider__slide {
  margin-bottom: 4rem;
}

#blog-single .slider__slide {
  @include breakpoint-down(tablet) {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}

.pagination {
  margin-bottom: 2rem;

  .screen-reader-text {
    display: none;
  }

  .nav-links {
    display: flex;
    justify-content: center;
  }

  .page-numbers {
    position: relative;
    font-family: $ff-montserrat;
    font-weight: bold;
    font-size: 20px;
    color: $c-blue;
    margin: 0 1rem;
    padding: 0 1rem;
    text-decoration: none;
    text-align: center;
    min-width: 34px;

    &.current {
      &:before {
        content: '';
        border-radius: 50%;
        border: .1rem solid $c-blue;
        width: 6rem;
        height: 6rem;
        display: block;
        opacity: .7;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include breakpoint-down(mobile) {
          top: -1.5rem;
        }
      }
    }

    &.prev,
    &.next {
      svg {
        max-width: 1rem;
      }
    }
  }
}