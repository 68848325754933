/* ------------------------------------------------------------ *\
	Properties
\* ------------------------------------------------------------ */

.properties {
  margin-bottom: 13.4rem;

  @include breakpoint-down(mobile) {
    margin-bottom: 7.6rem;
  }

  .properties__head {
    margin-bottom: 3.3rem;

    @include breakpoint-down(mobile) {
      margin-bottom: 3.5rem;
    }
  }

  .properties__body {
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(small-desktop) {
      flex-direction: column;
    }
  }

  .properties__title {
    @include breakpoint-down(mobile) {
      font-size: 2.4rem;
      line-height: 1.75;
    }
  }

  .properties__content {
    max-width: 71.8rem;
  }

  .properties__aside {
    max-width: 31rem;
    margin-left: 2rem;

    @include breakpoint-down(small-desktop) {
      margin-top: 3rem;
      margin-left: 0;
    }

    @include breakpoint-down(mobile) {
      margin-top: 3.5rem;
    }
  }

  .properties__aside .properties__title {
    margin-bottom: 2rem;
  }

  .properties__content > ul {
    list-style-type: none;
  }

  .properties__content > ul li + li {
    margin-top: 3.5rem;

    @include breakpoint-down(mobile) {
      margin-top: 2.9rem;
    }
  }

  .property-wrap {
    min-height: 13.4rem;
  }
}
