/* ------------------------------------------------------------ *\
	Card
\* ------------------------------------------------------------ */

.card {
  border-radius: 1.6rem 1.6rem 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .card__image {
    height: 24.5rem;
    width: 100%;
  }

  .card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: auto;
    background-color: $c-blue-light;
    padding: 2.7rem 3rem 4.5rem;

    @include breakpoint-down(mobile) {
      padding: 2.5rem 2rem 3rem;
    }
  }

  .card__title {
    line-height: 1.67;
    margin-bottom: 0.9rem;
  }

  .card__subtitle {
    display: block;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0.9rem;
  }

  .card__entry {
    //margin-bottom: 2.6rem;

    p {
      margin-bottom: 0;
    }
  }

  .card__actions {
    margin-top: auto;
  }
}

/*  card--alt  */

.card--alt {
  .card__image {
    position: relative;
    max-height: 180px;
  }

  .card__date {
    position: absolute;
    top: 0;
    left: 0;
    right: 60.2%;
    bottom: 0;
  }

  .card__body {
    padding: 2.1rem 3rem 2.4rem;

    @include breakpoint-down(tablet) {
      padding: 2.1rem 2rem;
    }
  }

  .card__title {
    margin-bottom: 1.9rem;
  }

  .card__subtitle {
    font-size: 1.4rem;
    line-height: 1.71;
    margin-bottom: 0;
  }

  .card__entry {
    margin-top: auto;
  }
}

/*  card--big  */

.card--big {
  border-radius: 1.6rem 0 0 1.6rem;
  flex-direction: row;

  @include breakpoint-down(mobile) {
    border-radius: 1.6rem 1.6rem 0 0;
    flex-direction: column;
  }

  .card__aside {
    flex: 0 0 40.8%;
    display: flex;

    @include breakpoint-down(tablet) {
      flex: 0 0 46.8%;
    }

    @include breakpoint-down(mobile) {
      position: relative;
    }
  }

  .card__image {
    height: auto;
    flex: 0 0 72.1%;

    @include breakpoint-down(mobile) {
      flex: 0 0 100%;
    }
  }

  .card__date {
    flex: 0 0 27.9%;

    @include breakpoint-down(mobile) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      min-width: 7rem;
      width: 30%;
    }
  }

  .card__body {
    padding: 2.6rem 8rem 3rem 5.8rem;

    @include breakpoint-down(tablet) {
      padding: 2.6rem 3.3rem 3rem;
    }

    @include breakpoint-down(mobile) {
      padding: 2.5rem 2rem 3rem;
    }

    & > p {
      margin-bottom: 2.3rem;
    }
  }

  .card__title {
    margin-bottom: 1.1rem;
  }

  .card__subtitle {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .card__entry {
    margin-bottom: 1.6rem;
  }

  .card__actions {
    margin-top: 0;
  }
}

/*  card--big-alt  */

.card--big-alt {
  .card__content {
    width: 100%;
  }

  .card__body {
    padding: 2.6rem 5.1rem 3rem 6rem;

    @include breakpoint-down(mobile) {
      padding: 2.5rem 2rem 3rem;
    }
  }

  .card__title {
    max-width: 55.3rem;
  }

  .card__text {
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(small-desktop) {
      flex-direction: column;
    }
  }

  .card__text p {
    max-width: 40rem;
  }

  .card__actions {
    display: inline-block;
    margin-left: 2rem;

    @include breakpoint-down(small-desktop) {
      margin-left: 0;
    }
  }
}
