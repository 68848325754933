/* ------------------------------------------------------------ *\
	Credits 
\* ------------------------------------------------------------ */

.credits {
	display: flex; 
	align-items: center; 

	@include breakpoint-down(mobile-medium) {
		flex-direction: column-reverse; 
	}

	p {
		margin-bottom: 0; 
		margin-right: 1.6rem; 

		@include breakpoint-down(mobile-medium) {
			margin-right: 0; 
		}
	}

	ul {
		margin-left: 1.6rem; 
		display: flex; 
		list-style-type: none; 

		@include breakpoint-down(mobile-medium) {
			margin-left: 0; 
			margin-bottom: 0.4rem; 
		}
	}

	li + li {
		margin-left: .4rem; 

		&:before {
			content: '|';
		}
	}

	a {
		text-decoration: none; 
		position: relative; 
		padding-bottom: .3rem; 
	}

	a:focus:after,
	a:hover:after {
		opacity: 1; 
	}

	& > ul a:after {
		position: absolute; 
		bottom: 0; 
		content: '';
		right: 0; 
		width: 100%; 
		height: .2rem; 
		display: block; 
		background-color: $c-white; 
		pointer-events: none; 
		opacity: 0; 
		transition: opacity $t-duration $animation; 
	}
}
