/* ------------------------------------------------------------ *\
	Address
\* ------------------------------------------------------------ */

.address {
	font-weight: 500; 
	font-size: 2rem; 
	line-height: 1.8; 
	color: $c-black; 
	display: flex; 
	align-items: center; 

	@include breakpoint-down(mobile) {
		justify-content: center; 
	}

	.address__entry {
		margin-left: 2.6rem; 

		p {
			margin-bottom: 0; 
		}
	}
}
