/* ------------------------------------------------------------ *\
	Availability
\* ------------------------------------------------------------ */

.availability {
  .availability-container {
    max-width: 96.5rem !important;
  }

  .properties__content {
    width: 100%;
  }
}
