/* ------------------------------------------------------------ *\
	Title-circle
\* ------------------------------------------------------------ */

.title-circle {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    border-radius: 50%;
    border: .1rem solid $c-grey3;
    width: 6.4rem;
    height: 6.4rem;
    display: block;
    opacity: .7;
    position: absolute;
    top: -0.9rem;
    left: -1.7rem;

    @include breakpoint-down(mobile) {
      top: -1.5rem;
    }
  }

  &-white {
    color: $c-white;
  }
}

/*  title-circle--big  */

.title-circle--big {
  &:before {
    width: 9.2rem;
    height: 9.2rem;
    opacity: .6;
    top: -2.6rem;
    left: -4.8rem;

    @include breakpoint-down(mobile) {
      top: -1.7rem;
      left: -2rem;
      height: 7rem;
      width: 7rem;
    }
  }
}

/*  title-circle--bigger  */

.title-circle--bigger {
  &:before {
    width: 11rem;
    height: 11rem;
    top: -2.4rem;
    left: -4.4rem;
    z-index: -1;

    @include breakpoint-down(tablet) {
      left: -1rem;
    }

    @include breakpoint-down(mobile) {
      width: 9rem;
      height: 9rem;
    }
  }
}

/*  title-circle--alt  */

.title-circle--alt {
  &:before {
    top: -0.6rem;
    left: -2.6rem;

    @include breakpoint-down(mobile) {
      left: -2rem;
      top: -1.3rem;
    }
  }
}
