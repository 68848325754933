/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {
  .subscribe-form {
    display: flex;

    @include breakpoint-down(mobile) {
      flex-direction: column;
    }

    .gform_body.gform-body {
      margin-bottom: 0;
      @include breakpoint-down(mobile) {
        margin-bottom: 2rem;
      }
    }

    input[type="submit"] {
      padding: 1rem 2rem !important;
    }
  }

  .subscribe__field,
  .subscribe__field .ginput_container input {
    margin-right: 2rem;
    flex: 0 0 66.9%;

    @include breakpoint-down(mobile) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  .subscribe__btn {
    border: .1rem solid $c-blue;
  }
}

/*  subscribe--small  */

.subscribe--small {
  form {
    flex-direction: column;
  }

  .subscribe__field,
  .subscribe__field .ginput_container input {
    margin: 0 0 2rem;
    flex: 0 0 100%;
  }

  .subscribe__btn {
    width: 100%;
  }
}
