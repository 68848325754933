/* ------------------------------------------------------------ *\
	List-links
\* ------------------------------------------------------------ */

.list-links {
	list-style-type: none; 

	li + li {
		margin-top: .7rem; 
	}

	a {
		font-size: 1.4rem; 
	}
}
