/* ------------------------------------------------------------ *\
	Event
\* ------------------------------------------------------------ */

.event {
  padding: 3.3rem 0 7.1rem;

  @include breakpoint-down(mobile) {
    padding: 3rem 0;
  }

  .event__inner {
    padding-left: 5rem;

    @include breakpoint-down(mobile-medium) {
      padding-left: 0;
    }
  }

  .event__head {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.4rem;

    @include breakpoint-down(small-desktop) {
      flex-direction: column-reverse;
    }
  }

  .event__head .event__title {
    margin-bottom: 0.6rem;
  }

  .event__head .event__entry {
    margin-bottom: 2.6rem;

    p {
      margin-bottom: .3rem;
      display: flex;
      justify-content: space-between;
    }
  }

  .event__entry span {
    display: inline-block;
  }

  .event__aside {
    max-width: 65rem;
    margin-left: 3rem;

    @include breakpoint-down(small-desktop) {
      margin-left: -4.4rem;
      margin-bottom: 3rem;
    }

    @include breakpoint-down(mobile-medium) {
      margin-left: 0;
    }

    .slick-track {
      max-height: 40rem;
    }
  }

  .event__body {
    max-width: 76rem;
  }

  .event__body .event__entry {
    margin-bottom: 5rem;
  }

  .event__body .event__title {
    margin-bottom: 1.7rem;
  }
}
