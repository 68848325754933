/* ------------------------------------------------------------ *\
	Date-circle
\* ------------------------------------------------------------ */

.date-circle {
	position: relative; 
	color: $c-white; 
	font-weight: 500; 
	font-size: 2.6rem; 
	text-align: center; 
	height: 100%; 
	display: flex; 
	align-items: center; 
	justify-content: center; 
	flex-direction: column; 
	padding-bottom: .6rem; 
	overflow: hidden; 

	&:before {
		content: '';
		position: absolute; 
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $c-black;
		opacity: .55; 
		display: block; 
	}

	&:after {
		content: '';
		position: absolute; 
		width: 14.5rem; 
		height: 14.5rem; 
		border-radius: 50%; 
		border: .1rem solid $c-white; 
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%); 
		opacity: .5; 
	}

	.date__month,
	.date__day,
	.date__year {
		position: relative; 
	}

	.date__month {
		font-size: 3rem; 
		line-height: 1.53; 
	}

	.date__day {
		font-size: 4.8rem; 
		font-weight: 700; 
		line-height: .96; 
	}
}

/*  date-circle--alt  */

.date-circle--alt {
	&:before {
		background-color: $c-blue;
		opacity: 1; 
	}

	&:after {
		width: 17.3rem; 
		height: 17.3rem; 
	}
}
