/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.feature {
	display: flex; 
	justify-content: space-between; 
	max-width: 36.1rem; 
	margin: 0 auto; 

	@include breakpoint-down(tablet) {
		flex-direction: column; 
		align-items: center; 
	}

	.feature__entry {
		margin-left: 1.4rem; 
		padding-top: 1.4rem; 
		line-height: 1.57; 

		@include breakpoint-down(tablet) {
			margin-left: 0; 
			text-align: center; 
		}
	}

	.feature__title {
		font-size: 2rem; 
		margin-bottom: 1.3rem; 
	}

	.feature__icon {
		flex: 0 0 11.1rem;

		@include breakpoint-down(tablet) {
			margin-bottom: 1.5rem; 
		}
	}
}

/*  feature--small  */

.feature--small {
	max-width: 26.3rem; 

	.feature__entry {
		padding-top: 0; 
		margin-left: 2.5rem; 
		line-height: 1.71; 

		@include breakpoint-down(tablet) {
			margin-left: 0; 
		}
	}

	.feature__icon {
		flex: 0 0 7.5rem;
	}

	.feature__title {
		font-size: 1.6rem; 
		margin-bottom: 0; 
	}
}
