/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video {
  padding: 3rem 0;

  .media {
    width: 50%;
    margin: 0 auto;

    @include breakpoint-down(mobile) {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}