/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
  color: $c-white;
  font-size: 1.8rem;
  margin-right: .7rem;
  transition: color $t-duration $animation;

  @include breakpoint-down(mobile) {
    color: $c-base;
    padding: 10rem 2rem 6rem;
    font-size: 2.2rem;
    margin-right: 0;
    height: calc(100% - 19.3rem);
    background-color: $c-white;
    -ms-overflow-y: scroll;
    overflow-y: scroll;
  }

  & > ul {
    display: flex;
    align-items: center;

    @include breakpoint-down(mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  & > ul li {
    @include breakpoint-down(mobile) {
      display: block;
      width: 100%;
    }

    & + li {
      margin-left: 4.5rem;

      @include breakpoint-down(small-desktop) {
        margin-left: 3rem;
      }

      @include breakpoint-down(tablet) {
        margin-left: 2.5rem;
      }

      @include breakpoint-down(mobile) {
        margin-left: 0;
        margin-top: 2.7rem;
      }
    }
  }

  a {
    text-decoration: none;
    position: relative;
    padding-bottom: .3rem;
    color: $c-white;

    @include breakpoint-down(mobile) {
      color: $c-base;
    }
  }

  a:focus:after,
  a:hover:after {
    opacity: 1;
  }

  & > ul a:after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: .2rem;
    display: block;
    background-color: $c-white;
    pointer-events: none;
    opacity: 0;
    transition: opacity $t-duration $animation;
  }

  // sub-menu - displays if menu item has children
  .menu > li.menu-item-has-children {
    position: relative;

    @include breakpoint-down(mobile) {
      position: relative;
      transition: color .3s, opacity .3s;

      &:before {
        content: '';
        position: absolute;
        background-image: url(../images/svg/angle-down-light.svg);
        width: 2rem;
        height: 3rem;
        background-repeat: no-repeat;
        top: .3rem;
        right: .2rem;
        color: $c-base;
        transition: transform .3s;
        cursor: pointer;
      }
    }

    &.is-active {
      color: $c-blue;

      &:before {
        transform: rotate(180deg);
        color: $c-blue;
      }
    }
  }

  .menu .menu-item-has-children .sub-menu {
    @include breakpoint-down(large-desktop) {
      left: auto;
      right: 0;
    }
  }

  .menu > li:hover .sub-menu {
    opacity: 1;
    pointer-events: all;
  }

  .sub-menu {
    position: absolute;
    top: calc(100% + 2.2rem);
    left: 0;
    z-index: 3;
    width: 32rem;
    padding: 1.6rem 2rem 1.8rem;
    border-top: .5rem solid $c-blue;
    background: $c-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    font-size: 1.5rem;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 34px;
    }

    @include breakpoint-down(mobile) {
      position: static;
      width: auto;
      margin: .8rem -3.6rem -1.7rem;
      padding: 1.8rem 4.3rem 1.8rem;
      border-top: 0;
      background: $c-blue-light;
      color: $c-black;
      opacity: 1;
      pointer-events: all;
      box-shadow: none;
      display: none;
    }
  }

  .sub-menu li {
    margin-bottom: .95rem;
    margin-left: 0;

    @include breakpoint-down(mobile) {
      margin-bottom: 1.36rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sub-menu a {
    transition: color .3s;
    color: $c-blue;

    &:hover {
      &:after {
        background-color: $c-blue;
        opacity: 1;
      }
    }
  }
}

/*  nav--sub displays in the header above the main nav */
.nav--sub {
  margin: 0 0 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4rem;

  @include breakpoint-down(mobile) {
    background-color: $c-blue-light;
    color: $c-blue;
    margin-bottom: 0;
    padding: 3.1rem 2rem 3.7rem;
    align-items: flex-end;
    font-size: 1.8rem;
    font-weight: 500;
    justify-content: space-between;
    height: 19.3rem;
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > ul {
    margin: .4rem 0 0;
  }

  & > ul li + li {
    margin-left: 4.6rem;

    @include breakpoint-down(small-desktop) {
      margin-left: 3rem;
    }

    @include breakpoint-down(tablet) {
      margin-left: 2.5rem;
    }

    @include breakpoint-down(mobile) {
      margin-left: 0;
      margin-top: 1.4rem;
    }
  }

  a {
    @include breakpoint-down(mobile) {
      color: $c-blue;
    }
  }
}
