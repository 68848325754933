/* ------------------------------------------------------------ *\
	Box-info
\* ------------------------------------------------------------ */

.box-info {
  position: relative;

  &:before {
    content: '';
    width: 1rem;
    height: .2rem;
    background-color: $c-blue;
    display: none;
    position: absolute;
    right: 0;
    top: 1.2rem;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  &:after {
    content: '';
    width: .2rem;
    height: 1rem;
    background-color: $c-blue;
    display: none;
    position: absolute;
    right: .4rem;
    top: .8rem;
    transition: top $t-duration $animation,
    right $t-duration $animation,
    height $t-duration $animation,
    width $t-duration $animation;

    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  & > a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
    display: block;

    @include breakpoint-down(mobile) {
      padding-right: 2rem;
    }
  }

  &.no-box-info {
    &:before,
    &:after {
      background-color: transparent;
    }
  }
}

.box-info.is-active {
  &:after {
    width: 1rem;
    height: .2rem;
    right: 0;
    top: 1.2rem;
  }
}
