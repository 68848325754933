/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		list-style-type: none;
		display: flex;

		li + li {
			margin-left: 1em;
		}
	}
}

/*  socials--alt  */

.socials--alt {
	margin-left: 3.8rem; 

	@include breakpoint-down(tablet) {
		margin-left: 2.5rem; 
	}

	a {
		width: 3.2rem; 
		height: 3.2rem; 
		border-radius: 50%; 
		border: .1rem solid $c-white; 
		font-size: 1.6rem; 
		display: flex; 
		align-items: center; 
		justify-content: center; 
		transition: 
			border-color $t-duration $animation,
			background-color $t-duration $animation,
			color $t-duration $animation; 

		@include breakpoint-down(mobile) {
			border: .1rem solid $c-blue; 
			width: 4.4rem; 
			height: 4.4rem; 
			font-size: 2.1rem; 
		}
	}

	a:focus,
	a:hover {
		color: $c-blue; 
		background-color: $c-white; 
	}
}

/*  socials--primary  */

.socials--primary {
	ul {
		display: flex; 
		align-items: center;
		justify-content: center;
	}
}
