/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */

@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/helpers';
@import '~select2/dist/css/select2.css';
@import '~slick-carousel/slick/slick.css';
@import '~magnific-popup/dist/magnific-popup.css';
@import '~jquery-ui-dist/jquery-ui.css';

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

@import 'generic/variables';
@import 'generic/functions';
@import 'generic/mixins';
@import 'generic/reset';
@import 'generic/fonts';
@import 'generic/base';
@import 'generic/forms';
@import 'generic/helpers';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import 'components/wrapper';
@import 'components/header';
@import 'components/logo';
@import 'components/nav';
@import 'components/main';
@import 'components/footer';
@import 'components/icons';
@import 'components/container';
@import 'components/hero';
@import 'components/btn-circle';
@import 'components/btn';
@import 'components/socials';
@import 'components/image-content';
@import 'components/title-circle';
@import 'components/link-more';
@import 'components/media';
@import 'components/background-decoration';
@import 'components/content';
@import 'components/select2';
@import 'components/select';
@import 'components/address';
@import 'components/cta';
@import 'components/subscribe';
@import 'components/field';
@import 'components/section-grey';
@import 'components/icon-callout';
@import 'components/icon-circle';
@import 'components/feature';
@import 'components/grid-content';
@import 'components/grid-images';
@import 'components/cards';
@import 'components/card';
@import 'components/slider';
@import 'components/date-circle';
@import 'components/image-grid';
@import 'components/slider-content';
@import 'components/slider-events';
@import 'components/accordion';
@import 'components/credits';
@import 'components/burger';
@import 'components/nav-inner';
@import 'components/properties';
@import 'components/property';
@import 'components/list-links';
@import 'components/section-tenants';
@import 'components/partner';
@import 'components/accordion-mobile';
@import 'components/box-info';
@import 'components/section-slider-date';
@import 'components/datepicker';
@import 'components/event';
@import 'components/list-cards';
@import 'components/social-images-grid';
@import 'components/section-socials';
@import 'components/section-article';
@import 'components/article';
@import 'components/sidebar';
@import 'components/tooltip';
@import 'components/body';
@import 'components/content-article';
@import 'components/image-box';
@import 'components/video';
@import 'components/availability';
@import 'components/tenant-directory';

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */

@import 'overwrites/wpsl';
@import 'overwrites/events';
@import 'overwrites/blog';
