/* ------------------------------------------------------------ *\
	Grid-content
\* ------------------------------------------------------------ */

.grid-content {
	overflow: hidden;
	padding: 3.2rem 0 0; 
	margin-bottom: 6.8rem; 

	.grid-content__inner {
		display: flex; 
		align-items: center; 

		@include breakpoint-down(tablet) {
			flex-direction: column; 
		}
	}

	.grid-content__grid {
		flex: 0 0 65.4%; 

		@include breakpoint-down(tablet) {
			flex: 0 0 100%; 
		}
	}

	.grid-content__body {
		flex: 0 0 45%; 
		position: relative; 
		max-width: 55.6rem; 
		padding: 9.6rem 0 8.1rem 20.5rem; 
		margin-left: -14.7rem; 

		@include breakpoint-down(tablet) {
			max-width: 100%; 
			padding: 13rem 7rem 8.1rem; 
			margin-top: -4rem; 
			margin-left: 0; 
			flex: 0 0 100%; 
		}

		@include breakpoint-down(mobile-medium) {
			margin-top: 0; 
			padding: 5rem 3rem 3rem 3.5rem; 
		}

		&:before {
			content: '';
			position: absolute; 
			left: 0; 
			top: 0; 
			width: 1000%; 
			height: 100%; 
			display: block; 
			z-index: -1; 
			background-color: $c-blue-light; 
		}

		p {
			margin-bottom: 2.2rem; 
		}
	}

	.grid-content__title {
		line-height: 1.25;
		margin-bottom: 2rem; 
	}
}

/*  grid-content--alt  */

.grid-content--alt {
	margin-bottom: 8.1rem; 

	.grid-content__inner {
		@include breakpoint-down(mobile-medium) {
			flex-direction: column-reverse; 
		}
	}

	.grid-content__body {
		flex: 0 0 51.9%; 
		max-width: revert; 
		padding: 7.3rem 9.7rem 4.3rem 11.4rem; 
		margin: 0 1.75rem 0 0; 
		background-color: $c-blue-light; 

		@include breakpoint-down(tablet) {
			margin: 0 0 -4rem; 
			padding: 9rem 7rem 11rem; 
		}

		@include breakpoint-down(mobile-medium) {
			padding: 9rem 3rem 3rem 3.5rem; 
			margin: -4rem 0 0; 
		}

		&:before {
			display: none; 
		}

		p {
			margin-bottom: 2.3rem; 
		}
	}

	.grid-content__grid {
		margin: 0 0 0 1.75rem;
		flex: 0 0 46.9%; 

		@include breakpoint-down(tablet) {
			position: relative; 
		}
	}
}
