/* ------------------------------------------------------------ *\
	Image-box
\* ------------------------------------------------------------ */

.image-box {
  width: 50.9rem;

  @include breakpoint-down(mobile-medium) {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
