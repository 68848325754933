//Variables
$font-size-base: 1.6rem;
$font-weight-base: 400;
$line-height-base: 1.72;

$ff-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$ff-serif: Georgia, "Times New Roman", Times, serif;
$ff-montserrat: 'Montserrat', sans-serif;

$t-duration: 0.3s;
$animation: ease-in-out;

$container-max-width: 130rem;
$container-small-max-width: 80rem;
$container-alt-max-width: 87rem;
$container-medium-max-width: 120rem;
$container-big-max-width: 144rem;

$container-gutter: 3.3rem;
$container-gutter-small: 2rem;
$container-gutter-mobile: 2rem;

$c-black: #000;
$c-white: #fff;
$c-base: #231F20;

$c-grey: #57585A;
$c-grey2: #888888;
$c-grey3: #CACACA;

$c-blue: #165CAB;
$c-blue-light: #F3F5F7;

$responsive-ios: 812px;

$breakpoints: (
        mobile-small: 374px,
        mobile-smedium: 480px,
        mobile-medium: 575px,
        mobile: 767px,
        admin-bar: 782px,
        tablet: 1023px,
        small-desktop: 1199px,
        large-desktop: 1399px
);
