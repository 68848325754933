/* ------------------------------------------------------------ *\
	Image-grid
\* ------------------------------------------------------------ */

.image-grid {
	margin-bottom: 12.3rem; 

	@include breakpoint-down(mobile) {
		margin-bottom: 5rem; 
	}
}
