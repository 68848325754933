/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
  font-size: 10px;
}

body {
  min-width: 320px;
  background: #fff;
  font-family: $ff-montserrat;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $c-base;
}

a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &[href^="tel"] {
    text-decoration: none;
  }
}


.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: calc(#{$line-height-base}em / 2);
}

.h1,
h1 {
  font-size: 4.2rem;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .025em;
  color: $c-black;

  @include breakpoint-down(mobile) {
    font-size: 3rem;
    line-height: 1.53;
  }
}

.h2,
h2 {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.44;
  color: $c-blue;

  @include breakpoint-down(mobile) {
    font-size: 2.6rem;
    line-height: 1.54;
  }
}

.h3,
h3 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.44;
  color: $c-grey;

  @include breakpoint-down(mobile) {
    font-size: 2.3rem;
  }
}

.h4,
h4 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.8;
  color: $c-black;

  @include breakpoint-down(mobile) {
    font-size: 1.8rem;
    line-height: 1.56;
  }
}

.h5,
h5 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2;
  color: $c-blue;
  text-transform: uppercase;

  @include breakpoint-down(mobile) {
    font-size: 1.7rem;
  }
}

.h6,
h6 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: $c-black;
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

.blue-bullets {
  list-style: none;
  margin-bottom: 2.1rem;

  li {
    padding-left: 1.6rem;
    position: relative;

    &:before {
      content: '';
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      background-color: $c-blue;
      position: absolute;
      top: .8rem;
      left: .1rem;
    }
  }

  li + li {
    margin-top: .4rem;
  }
}
