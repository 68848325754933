/* ------------------------------------------------------------ *\
	Section-tenants
\* ------------------------------------------------------------ */

.section-tenants {
	padding: 11.3rem 0; 
	background-color: $c-blue-light; 

	@include breakpoint-down(mobile) {
		padding: 6.1rem 0 1.1rem; 
	}

	.section__head {
		display: flex; 
		justify-content: space-between; 
		align-items: center; 
		margin-bottom: 4.7rem; 

		@include breakpoint-down(small-desktop) {
			flex-direction: column; 
			align-items: flex-start; 
		}
	}

	.section__actions {
		@include breakpoint-down(small-desktop) {
			margin-top: 2rem; 
		}

		@include breakpoint-down(mobile-medium) {
			width: 100%; 
		}
	}

	.section__title {
		margin-bottom: 1.8rem; 

		@include breakpoint-down(mobile) {
			margin-bottom: 1.1rem; 
		}
	}

	.section__head .section__entry {
		max-width: 72.2rem; 
	}

	.section__body {
		display: flex; 
		align-items: center; 
		justify-content: space-between; 
		padding-right: .4rem; 
		margin-bottom: 8.7rem; 

		@include breakpoint-down(small-desktop) {
			flex-direction: column; 
			align-items: flex-start; 
		}

		@include breakpoint-down(mobile) {
			padding-right: 0; 
			margin-bottom: 3rem; 
		}
	}

	.section__subtitle {
		max-width: 31.9rem; 

		@include breakpoint-down(small-desktop) {
			margin-bottom: 2rem; 
		}
	}

	.section__slider {
		max-width: 70.3%; 
		width: 100%; 

		@include breakpoint-down(small-desktop) {
			max-width: 100%; 
		}
	}

	.section__foot {
		display: flex; 
		justify-content: space-between; 

		@include breakpoint-down(mobile) {
			flex-direction: column; 
		}
	}

	.section__foot .section__entry {
		margin-bottom: 4.5rem; 

		@include breakpoint-down(mobile) {
			margin-bottom: 3.5rem; 
		}
	}

	.section__foot .section__entry p {
		max-width: 26.2rem; 
		margin-bottom: 2rem; 

		@include breakpoint-down(mobile) {
			max-width: 100%; 
		}
	}

	.section__foot .section__subtitle {
		margin-bottom: 1.8rem; 

		@include breakpoint-down(mobile) {
			margin-bottom: 1.4rem; 
		}
	}

	.section__aside {
		max-width: 31.9rem; 
		margin-right: 3.6rem; 

		@include breakpoint-down(mobile) {
			max-width: 100%; 
			margin-right: 0; 
		}
	}

	.section__content {
		max-width: 84.5rem; 
		width: 100%; 
		padding-top: .9rem; 

		@include breakpoint-down(mobile) {
			max-width: revert; 
			width: revert; 
			padding-top: 0; 
			margin: 0 -2rem 3.5rem; 
		}
	}
}
