/* ------------------------------------------------------------ *\
  Forms
\* ------------------------------------------------------------ */

.gform_wrapper {

  ul,
  li {
    list-style: none;
  }

  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    margin-bottom: 0 !important;
  }

  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="color"],
  textarea,
  select {
    position: relative;
    padding: 1rem !important;
    font-family: $ff-sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.6rem !important;
    border: thin solid $c-grey3;
    width: 100% !important;
    transition: 0.4s;

    &::placeholder {
      font-size: 1.4rem;
      font-weight: 400;
    }

    &:focus {
      border: thin solid $c-blue;
      background: white;
    }

    @include breakpoint-down(mobile) {
      line-height: unset !important;
      min-height: unset !important;
    }
  }

  select {
    background-color: $c-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include breakpoint-down(mobile) {
      line-height: 2.75rem;
    }

    &::-ms-expand {
      display: none;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    outline-color: transparent;
    background-color: transparent;
    font: inherit;
    color: currentColor;
    top: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid currentColor;
    padding: 0 !important;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }

    &:checked::before {
      content: '';
      background-color: $c-blue;
    }
  }

  input[type="checkbox"] {
    &:before {
      left: 54%;
    }
  }

  input[type="radio"] {
    border-radius: 50%;
    min-height: unset;

    &:before {
      background-color: transparent;
      border-radius: 50%;
    }

    &:checked::before {
      content: '';
      background-color: $c-blue;
      border-radius: 50%;
    }
  }

  .gfield {
    position: relative;
  }

  .gfield--width-full {
    width: 100% !important;
  }

  .gfield_label {
    font-family: $ff-montserrat !important;
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
  }

  .gfield--width-half {
    display: block;
    width: 100%;
  }

  .gform_button_select_files {
    padding: 0.5rem 1rem;
  }

  .ginput_container_select,
  .ginput_address_country {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background-image: url(../images/svg/angle-down-light.svg);
      width: 2rem;
      height: 3rem;
      background-repeat: no-repeat;
      color: $c-base;
      top: 50%;
      right: 2.5rem;
      transform: translateY(-50%);
    }
  }

  .ginput_address_country {
    &:after {
      top: 32%;
    }
  }

  .gfield_required_asterisk,
  .gfield_validation_message,
  .gform_submission_error {
    color: $c-blue;
  }

  .gfield_validation_message {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 90%;
    z-index: 1;
  }

  .gfield_error {
    .ginput_container {
      margin-bottom: 3rem;
    }
  }

  .ginput_container_checkbox,
  .ginput_container_radio {
    + .gfield_validation_message {
      top: calc(100% - 0.36rem);
      left: 0;
    }
  }

  .ginput_container_textarea {
    + .gfield_validation_message {
      top: calc(100% - 0.84rem);
    }
  }

  .gform_submission_error {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 2.5rem;
  }

  .gform_validation_errors {
    + form {
      .gfield--width-half {
        .ginput_container {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .labelPlaceholder {
    position: relative;

    .gfield_label {
      position: absolute;
      top: 1.7rem;
      left: 2.5rem;
      color: $c-blue;
      font-size: 2rem;
      font-weight: 500;
      z-index: 1;

      &.hide {
        display: none;
      }
    }
  }

  input[type=submit] {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
  }

  .gform_ajax_spinner {
    display: none;
  }
}
