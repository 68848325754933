/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
  max-width: calc(#{$container-max-width} + #{$container-gutter} * 2) !important;
  padding-right: $container-gutter;
  padding-left: $container-gutter;
  margin: auto;
  width: 100%;

  @include breakpoint-down(tablet) {
    padding-right: $container-gutter-small;
    padding-left: $container-gutter-small;
  }

  @include breakpoint-down(mobile) {
    padding-right: $container-gutter-mobile;
    padding-left: $container-gutter-mobile;
  }
}

.container--small {
  max-width: calc(#{$container-small-max-width} + #{$container-gutter} * 2) !important;
}

.container--medium {
  max-width: calc(#{$container-medium-max-width} + #{$container-gutter} * 2) !important;
}

.container--big {
  max-width: calc(#{$container-big-max-width} + #{$container-gutter-small} * 2) !important;
  padding-right: $container-gutter-small;
  padding-left: $container-gutter-small;
}

.container--alt {
  max-width: calc(#{$container-alt-max-width} + #{$container-gutter} * 2) !important;
}

/*  container--fluid  */

.container--fluid {
  max-width: none !important;
  padding: 0;
  width: 100%;
}
