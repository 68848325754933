/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	.article__head {
		margin-bottom: 4.7rem; 
	}

	.article__image {
		margin-left: -12rem; 
		height: 40rem; 

		@include breakpoint-down(tablet) {
			margin-left: 0; 
		}
	}

	.article__image img {
		width: 100%; 
		height: 100%; 
		object-fit: cover; 
	}

	.article__body blockquote {
		font-size: 2.2rem; 
		line-height: 1.45; 
		color: $c-grey; 
		text-align: center; 
		margin: 3.7rem 0 3.4rem; 
	}

	.article__subtitle {
		margin: 3.3rem 0 1.5rem; 
	}
}
