/* ------------------------------------------------------------ *\
	Btn-circle
\* ------------------------------------------------------------ */

.btn-circle {
	width: 14.6rem; 
	height: 14.6rem; 
	border-radius: 50%; 
	border: .1rem solid rgba($c-grey3, .85); 
	display: flex; 
	justify-content: center; 
	align-items: center; 
	color: $c-white; 
	font-size: 3.3rem; 

	svg {
		animation: move-in $animation 2s infinite;
	}

	@keyframes move-in {
		0% {
			transform: translateY(0); 
		}

		50% {
			transform: translateY(60%); 
		}

		100% {
			transform: translateY(0); 
		}
	}
}
