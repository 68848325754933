/* ------------------------------------------------------------ *\
	Slider Events
\* ------------------------------------------------------------ */

.slider-events {
  padding: 15rem 0 12rem 0;

  @include breakpoint-down(tablet) {
    padding: 10rem 0 8rem 0;
  }

  @include breakpoint-down(mobile) {
    padding: 5rem 0;
  }

  .slider {
    margin-bottom: 5rem;
  }
}
