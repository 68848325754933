/* ------------------------------------------------------------ *\
	Icon-callout
\* ------------------------------------------------------------ */

.icon-callout {
	margin-bottom: 10rem; 
	padding: .6rem 0 0;

	.title-circle {
		display: inline-block;
	}

	.icon-callout__head {
		text-align: center; 
		margin: 0 auto 6rem; 
		max-width: 76.2rem; 
	}

	.icon-callout__title {
		display: inline-block; 
		margin-bottom: 2.1rem; 
	}
}

/*  icon-callout--boxed  */

.icon-callout--boxed {
	padding: 0; 

	.icon-callout__inner {
		background-color: $c-blue-light; 
		padding: 5.7rem 16.5rem 6rem; 

		@include breakpoint-down(small-desktop) {
			padding: 5.7rem 5.5rem 6rem; 
		}

		@include breakpoint-down(tablet) {
			padding: 5.7rem 3rem 6rem; 
		}
	}
}
