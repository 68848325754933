/* ------------------------------------------------------------ *\
	Background-decoration
\* ------------------------------------------------------------ */

.background-decoration {
	position: relative;
	height: 100%;

	&:before {
		content: '';
		background-image: url('../images/pattern.svg');
		background-repeat: repeat;
		position: absolute; 
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;

		@include breakpoint-down(mobile) {
			background-size: 7.5rem;
			opacity: .7; 
		}
	}

	&:after {
		content: '';
		position: absolute; 
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block; 
		background: linear-gradient(-90deg, rgba(243,245,247,1) 49%, rgba(243,245,247,0) 100%);

		@include breakpoint-down(mobile) {
			background: linear-gradient(-90deg, rgba(243,245,247,1) 50%, rgba(243,245,247,0) 100%);
		}
	}
}

/*  background-decoration--reverse  */

.background-decoration--reverse {
	&:after {
		background: linear-gradient(90deg, rgba(243,245,247,1) 49%, rgba(243,245,247,0) 100%);

		@include breakpoint-down(mobile) {
			background: linear-gradient(90deg, rgba(243,245,247,1) 50%, rgba(243,245,247,0) 100%);
		}
	}
}
