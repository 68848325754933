/* ------------------------------------------------------------ *\
	Select2
\* ------------------------------------------------------------ */

.select2 {
	width: 30rem !important; 
	height: revert; 

	@include breakpoint-down(mobile) {
		width: 100% !important; 
	}

	.select2-selection__placeholder {
		color: $c-base !important;
		font-size: 1.4rem; 
		font-weight: 500; 
	}

	.select2-selection--single,
	.select2-container--default {
		border-radius: 0; 
	}

	.select2-selection--single {
		height: revert;
		border: .1rem solid $c-grey3; 
	}

	.select2-selection__rendered {
		padding: 0.6rem 4.5rem 0.5rem 1.2rem !important; 
	}

	.select2-selection__arrow {
		transform: translateY(-50%); 
		top: 50% !important; 
		right: 1.7rem !important; 
		transition: transform $t-duration $animation; 
	}

	
	.select2-container--open .select2-selection__arrow {
		transform: translateY(-50%) rotate(180deg); 
	}

	.select2-selection__arrow b {
		background-image: url('../images/svg/chevron-down.svg');
		background-color: transparent;
		background-size: contain;
		background-repeat: no-repeat;
		border: none !important;
		height: 1.4rem !important;
		width: 1.4rem !important;
		margin: auto !important;
		left: auto !important;
		top: 50%; 
		transform: translateY(-50%); 
	}
}
