/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
  position: relative;

  .slider__actions {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  .slider__prev,
  .slider__next {
    text-decoration: none;
    font-size: 3.2rem;
    color: $c-blue;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    border: .1rem solid $c-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color $t-duration $animation,
    background-color $t-duration $animation;

    &:focus,
    &:hover {
      color: $c-white;
      background-color: $c-blue;
    }
  }

  .slider__prev--alt,
  .slider__next--alt {
    color: $c-white;
    border: .1rem solid $c-white;

    &:focus,
    &:hover {
      color: $c-blue;
      background-color: $c-white;
    }
  }

  .slider__prev {
    transform: rotate(180deg);
  }
}

/*  slider--main  */

.slider--main {
  .slider__clip {
    padding: 0 4.4rem;

    @include breakpoint-down(mobile-medium) {
      padding: 0;
    }
  }

  .slider__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    @include breakpoint-down(mobile-medium) {
      object-fit: contain;
    }
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide,
  .slick-slide > div,
  .slick-slide .slider__slide {
    height: 100%;
  }

  .slick-track {
    display: flex !important;
  }

  .slider__actions {
    position: static;
    transform: none;

    @include breakpoint-down(mobile) {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }

  .slider__prev,
  .slider__next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @include breakpoint-down(mobile-medium) {
      position: static;
      transform: none;
      margin: 0 1rem;
      font-size: 2.4rem;
      width: 4rem;
      height: 4rem;

    }
  }

  .slider__prev {
    transform: translateY(-50%) rotate(180deg);
    left: 0;
    right: revert;

    @include breakpoint-down(mobile-medium) {
      transform: translateY(0) rotate(180deg);
    }
  }
}

/*  slider--main-alt  */

.slider--main-alt {
  .slider__clip {
    padding: 0;

    @include breakpoint-down(mobile) {
      padding: 0 0 2rem;
    }
  }

  .slick-list {
    @include breakpoint-down(mobile) {
      padding: 0;
    }
  }

  .slick-slide {
    @include breakpoint-down(mobile) {
      max-width: 30rem;
    }
  }

  .slider__actions {
    display: none;
  }

  .slider__prev,
  .slider__next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @include breakpoint-down(mobile) {
      transform: translateY(0);
      position: static;
      margin: 0 1rem;
    }
  }

  .slider__prev {
    transform: translateY(-50%) rotate(180deg);
    left: 0;
    right: revert;

    @include breakpoint-down(mobile) {
      transform: translateY(0) rotate(180deg);
    }
  }
}

/*  sliders--cards  */

.slider--cards {
  .slider__slides {
    @include breakpoint-down(mobile) {
      margin: 0 -2rem;
    }
  }

  .slider__slide {
    margin-bottom: 4.1rem;

    @include breakpoint-down(mobile) {
      margin-bottom: 0;
      max-width: 30rem;
      padding: 0 1rem;
    }
  }

  .slick-slide {
    @include breakpoint-down(mobile) {
      height: inherit !important;
    }
  }

  .slick-list {
    @include breakpoint-down(mobile) {
      padding: 0 1rem;
    }
  }

  .slick-slide > div,
  .slick-slide .slider__slide {
    @include breakpoint-down(mobile) {
      height: 100%;
    }
  }

  .slick-track {
    @include breakpoint-down(mobile) {
      display: flex !important;
    }
  }
}

/*  sliders--cards-alt  */

.slider--cards-alt {
  .slider__slide {
    margin-bottom: 0;

    @include breakpoint-down(tablet) {
      margin-bottom: 4.1rem;
    }
  }
}

/*  slider--cards-small  */

.slider--cards-small {
  padding: 0 8rem;

  @include breakpoint-down(tablet) {
    padding: 0;
  }

  .slider__clip {
    position: relative;
    z-index: 2;
  }

  .slider__slides {
    //overflow: hidden;
  }

  .slider__slide {
    @include breakpoint-down(mobile) {
      max-width: 28.4rem;
    }
  }

  .slick-list {
    margin: 0 -1.75rem;

    @include breakpoint-down(small-desktop) {
      margin: 0 -1rem;
    }

    @include breakpoint-down(mobile) {
      overflow: visible;
    }
  }

  .slick-slide {
    padding: 0 1.75rem;
    height: inherit !important;

    @include breakpoint-down(small-desktop) {
      padding: 0 1rem;
    }
  }

  .slick-slide > div,
  .slick-slide .slider__slide {
    height: 100%;
  }

  .slick-track {
    display: flex !important;
  }

  .slider__actions {
    @include breakpoint-down(tablet) {
      position: static;
      transform: none;
      justify-content: center;
      margin-top: 2rem;
      display: none;
    }
  }

  .slider__prev,
  .slider__next {
    @include breakpoint-down(tablet) {
      margin: 0 1rem;

      &:focus,
      &:hover {
        color: $c-blue;
        background-color: transparent;
      }
    }
  }
}

/*  slider--cards-big  */

.slider--cards-big {
  .slider__slide + .slider__slide {
    margin-top: 3.3rem;
  }

  .slick-slide {
    @include breakpoint-down(mobile) {
      height: 100% !important;
    }
  }
}

/*  slider--circles  */

.slider--circles {
  .slider__slides {
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(mobile) {
      flex-direction: column;
      margin: 0 -2.3rem;
    }
  }

  .slider__slide {
    @include breakpoint-down(mobile) {
      max-width: 22.2rem;
      padding: 0 2.3rem;
    }
  }
}

/*  slider--date  */

.slider--date {

  .slider__clip {
    padding: 3.5rem 0;
  }

  .slider__actions {
    position: static;
    transform: none;
    background-color: $c-blue;
    padding: 1.5rem 3rem;
    align-items: center;
    display: flex;

    @include breakpoint-down(mobile) {
      padding: 1.5rem 2rem;
    }
  }

  .slider__prev,
  .slider__next {
    @include breakpoint-down(mobile-medium) {
      font-size: 2.6rem;
      width: 4.4rem;
      height: 4.4rem;
      flex: 0 0 4.4rem;
    }
  }

  .slider__actions--top {
    border-radius: 1.6rem 1.6rem 0 0;
  }

  .slider__actions--bottom {
    border-radius: 0 0 1.6rem 1.6rem;
  }
}
