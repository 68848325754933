/* ------------------------------------------------------------ *\
	Field
\* ------------------------------------------------------------ */

.field,
.ginput_container input,
.ginput_container textarea,
.ginput_container select {
  border: .1rem solid $c-grey3;
  padding: 1.1rem 1.9rem;

  &::placeholder {
    color: $c-grey;
  }
}
