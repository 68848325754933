/* ------------------------------------------------------------ *\
	Section-grey
\* ------------------------------------------------------------ */

.section-grey {
	ul {
		list-style: none; 
		margin-bottom: 2.1rem; 
	}

	li {
		padding-left: 1.6rem; 
		position: relative; 
	}

	li + li {
		margin-top: .4rem; 
	}

	li:before {
		content: '';
		width: .8rem; 
		height: .8rem; 
		border-radius: 50%; 
		background-color: $c-blue; 
		position: absolute; 
		top: .8rem; 
		left: .1rem; 
	}

	.section__content {
		position: relative; 
	}

	.section__content:before {
		content: '';
		position: absolute; 
		top: 0; 
		left: 0; 
		height: 100%; 
		width: 1000%; 
		background-color: $c-blue-light; 
		display: block; 
		z-index: -1; 

		@include breakpoint-down(mobile) {
			width: 100%; 
		}
	}
}
