/* ------------------------------------------------------------ *\
	Partner
\* ------------------------------------------------------------ */

.partner {
	width: 17.5rem; 
	height: 17.5rem; 
	border-radius: 50%; 
	border: .1rem solid $c-blue; 
	display: flex; 
	justify-content: center; 
	align-items: center; 
	position: relative; 

	img {
		position: relative; 
	}

	&:before {
		content: '';
		width: 13.5rem; 
		height: 13.5rem; 
		background-color: $c-white; 
		display: block; 
		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%); 
		border-radius: 50%; 
	}
}
