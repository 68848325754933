/* ------------------------------------------------------------ *\
	WPSL
\* ------------------------------------------------------------ */

.wpsl-locations-details,
.wpsl-location-address,
.wpsl-contact-details {
  margin-bottom: 0px;
}

.wpsl-search {
  background: transparent;
  padding-right: 0;
  margin-bottom: 24px;
  padding-left: 0;
}

#wpsl-wrap {
  min-height: 400px;

  a {
    text-decoration: none;
    color: $c-blue;

    &:hover {
      //text-decoration: underline;
    }
  }

  #wpsl-search-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .wpsl-view {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding-top: 6px;

      > span {
        &:before {
          content: "";
          margin: 0 0 -5px 12px;
          width: 15px;
          height: 23px;
          border-left: 1px solid #ababab;
          position: relative;
          display: inline-block;
        }

        &:first-child:before {
          margin: 0;
          padding: 0;
          border-left: none;
          content: none;
        }
      }
    }

    @include breakpoint-down(mobile) {
      display: block;

      .wpsl-view {
        display: block;
        margin-bottom: 12px;
      }
    }

    #wpsl-category {
      margin-right: 0;
      display: flex;
      align-items: center;

      label {
        float: none;
        width: auto;
      }
    }

    div {
      float: none;
    }
  }

  .wpsl-preloader {
    //flex-direction: row-reverse;
    //justify-content: flex-start;
    color: transparent;

    img {
      float: none;
      position: relative;
      top: auto;
      margin-top: 0;
      height: 16px;
      width: 16px;
    }
  }

  #wpsl-result-list #wpsl-stores ul li,
  .wpsl-info-window, {
    h5 {
      margin-bottom: 8px;
    }

    .wpsl-street {
      font-weight: bold;
      font-size: 1.6rem;
      margin-bottom: 10px;
    }

    .wpsl-metainfo {
      display: block;
      font-size: 14px;
    }

    .link-more {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &.grid-view {
    .wpsl-grid-view {
      font-weight: bold;
    }

    #wpsl-gmap {
      display: none;
    }

    #wpsl-result-list {
      width: 100%;
      margin-right: 0;

      #wpsl-stores {
        height: auto !important;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            width: 30%;
            max-width: 370px;
            background-color: #f3f5f7;
            border-radius: 16px 16px 0px 0px;
            border-bottom: none;
            margin: 0 0 48px 0;
            padding: 0 30px 30px 30px;

            @include breakpoint-down(mobile) {
              width: calc(50% - 2rem);
            }

            @include breakpoint-down(mobile-medium) {
              width: calc(50% - 1rem);
            }

            @include breakpoint-down(mobile-smedium) {
              width: 100%;
              max-width: unset;
            }

            .wpsl-photo {
              margin-left: -30px;
              margin-right: -30px;
              display: inline-block;

              &-fallback {
                display: flex;
                justify-content: center;
              }
            }

            .wpsl-store-thumb {
              margin: 0;
              border-radius: 0;

              &-fallback {
                object-fit: contain;
                max-width: 14rem;
              }
            }

            h5 {
              margin-top: 14px;
            }

            &.wpsl-preloader {
              background-color: transparent;
              padding: 0;
            }
          }
        }
      }
    }
  }

  &.map-view {
    .wpsl-map-view {
      font-weight: bold;
    }

    #wpsl-gmap {
      .gm-style {
        .gm-style-iw-c {
          padding: 0px;
          border-radius: 16px 16px 0px 16px;

          > button[style] {
            top: 0 !important;
            right: 0 !important;
          }

          .gm-style-iw-d {
            font-family: "Montserrat", sans-serif;
            overflow: hidden !important;
            padding: 0;
            width: 100%;

            .wpsl-photo {
              width: 100%;

              img {
                width: 100%;
                min-width: 370px;
              }
            }

            .wpsl-info-window-content {
              padding: 30px;

              a:last-child {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }

    #wpsl-result-list {
      #wpsl-stores {
        ul {
          li {
            padding: 16px 0;
            border-bottom: 2px solid #dfdfdf;
            max-width: 90%;

            .wpsl-photo {
              display: none;

              &-fallback {
                display: none;
              }
            }

            &.wpsl-preloader {
              float: none;
              border-bottom: none;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}