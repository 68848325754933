/* ------------------------------------------------------------ *\
	Image-content
\* ------------------------------------------------------------ */

.image-content {
  margin-bottom: 7.6rem;
  padding: 7.4rem 0 2.4rem;

  @include breakpoint-down(tablet) {
    margin-bottom: 5rem;
    padding: 6rem 0 2rem;
  }

  @include breakpoint-down(mobile) {
    margin-bottom: 3rem;
    padding: 3rem 0 2rem;
  }

  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    margin-bottom: calc(#{$line-height-base}em / 2);
  }

  &.text-align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  .image-content__body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint-down(mobile) {
      flex-direction: column;
    }

    &-reversed {
      flex-direction: row-reverse;

      @include breakpoint-down(mobile) {
        flex-direction: column;
      }

      .section__content:before {
        right: 0;
        left: unset;
      }

      .image-content__content {
        padding: 6.3rem 3rem 3rem 7rem;
      }
    }
  }

  .image-content__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .image-content__aside {
    max-width: 52.5rem;
    width: 100%;
    margin: .2rem 5.4rem 0 0;
    position: relative;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      margin: 0 0 4rem;
    }
  }

  .image-content__image {
    width: 100%;
    height: 100%;
  }

  .image-content__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-content__title {
    color: $c-blue;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .image-content__title--alt {
    font-size: 2.7rem;
  }

  .image-content__content {
    max-width: 41.9%;
    margin: 0 10rem 0 0;
    position: relative;

    @include breakpoint-down(tablet) {
      margin: 0;
    }

    @include breakpoint-down(mobile) {
      max-width: 100%;
      padding: 0 4rem;
    }

    @include breakpoint-down(mobile-medium) {
      padding: 0 3rem;
    }
  }

  .image-content__actions--space-top {
    margin-top: 9.3rem;

    @include breakpoint-down(mobile) {
      margin-top: 3rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
}

/*  image-content--grid  */

.image-content--grid {
  overflow: hidden;

  .image-content__aside {
    max-width: 65.5rem;
    width: 100%;
    margin: .2rem 2rem 0 0;

    @include breakpoint-down(mobile) {
      margin: 0;
      max-width: 100%;
    }
  }

  .image-content__content {
    max-width: 45%;
    margin: 0;
    padding: 6.3rem 0 3rem 7rem;

    @include breakpoint-down(tablet) {
      padding: 6.3rem 0 3rem 4rem;
    }

    @include breakpoint-down(mobile) {
      max-width: 100%;
      padding: 5rem 3rem 3rem 3.5rem;
    }
  }

  .image-content__title {
    line-height: 1.25;
    margin-bottom: 2.2rem;
  }

  .image-content__body {
    margin-bottom: 6rem;
  }

  .image-content__foot .row {
    margin: 0 -3.05rem;

    @include breakpoint-down(small-desktop) {
      margin: 0 -3rem;
    }

    @include breakpoint-down(tablet) {
      margin: 0 -1rem;
    }

    @include breakpoint-down(mobile) {
      margin: 0 -1rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-content__col {
    padding: 0 3.05rem;

    @include breakpoint-down(small-desktop) {
      padding: 0 2rem;
    }

    @include breakpoint-down(tablet) {
      padding: 0 1rem;
    }
  }
}

/*  image-content--grid-alt  */

.image-content--grid-alt {
  .image-content__content {
    padding: 8.4rem 0 3rem 7rem;


    @include breakpoint-down(tablet) {
      padding: 7rem 0 3rem 4rem;
    }

    @include breakpoint-down(mobile) {
      padding: 5rem 3rem 3rem 3.5rem;
    }
  }
}


/*  image-content--boxed  */

.image-content--boxed {
  padding: 7.7rem 0 7.4rem;
  margin-bottom: 0;

  @include breakpoint-down(tablet) {
    padding: 6rem 0;
  }

  .image-content__inner {
    background-color: $c-blue-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11rem 9rem 13.3rem 8.7rem;
    position: relative;

    @include breakpoint-down(tablet) {
      padding: 7rem 4rem;
    }

    @include breakpoint-down(mobile) {
      flex-direction: column;
      padding: 5rem 2rem;
      overflow: hidden;
    }
  }

  .image-content__aside {
    max-width: 48.5rem;
    margin: 0.2rem 5.4rem 0 0;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      margin: 0;
    }
  }

  .image-content__content {
    margin-right: 0;
    max-width: 44.8%;

    @include breakpoint-down(tablet) {
      max-width: 40%;
    }

    @include breakpoint-down(mobile) {
      max-width: 100%;
      padding: 4rem 0 0;
    }
  }

  .image-content__actions {
    margin-top: 2rem;
  }
}

.image-content + .image-content {
  padding-top: 0;
}
