/* ------------------------------------------------------------ *\
	Slider-content
\* ------------------------------------------------------------ */

.slider-content {
	margin-bottom: 11.8rem; 

	@include breakpoint-down(tablet) {
		margin-top: 5rem; 
	}

	@include breakpoint-down(mobile) {
		margin-bottom: 5rem; 
	}

	.slider-content__inner {
		display: flex; 
		align-items: center; 
		padding: 0 2.6rem; 

		@include breakpoint-down(small-desktop) {
			padding: 0; 
		}

		@include breakpoint-down(tablet) {
			padding: 0 2.6rem;
			flex-direction: column; 
		}

		@include breakpoint-down(mobile) {
			padding: 0; 
		}
	}

	.slider-content__slider {
		max-width: 59.7rem; 

		@include breakpoint-down(small-desktop) {
			max-width: 57.7rem; 
		}

		@include breakpoint-down(mobile) {
			width: 100%; 
		}
	}

	.slider-content__body {
		padding: 0 0 0 5.6rem; 
		margin-top: -.3rem; 

		@include breakpoint-down(tablet) {
			margin-top: 5rem;
			padding: 0 4.4rem; 
		}

		@include breakpoint-down(mobile-medium) {
			padding: 0 3rem; 
		}

		p {
			margin-bottom: 1.9rem; 
		}
	}

	.slider-content__title {
		margin-bottom: 1.7rem;
	}

	.slider-content__list {
		list-style: none; 
	}

	.slider-content__list li {
		padding-left: 1.6rem; 
		position: relative; 

		&:before {
			content: '';
			width: .8rem; 
			height: .8rem; 
			border-radius: 50%; 
			background-color: $c-blue; 
			position: absolute; 
			top: .8rem; 
			left: .1rem; 
		}
	}

	.slider-content__list li + li {
		margin-top: .6rem; 
	}
}

/*  slider-content--alt  */

.slider-content--alt {
	.slider-content__inner {
		padding: 0; 
		margin-left: -4.4rem; 

		@include breakpoint-down(small-desktop) {
			margin-left: 0; 
		}
	}
}
