/* ------------------------------------------------------------ *\
	Section-socials
\* ------------------------------------------------------------ */

.section-socials {
	margin-bottom: 10.9rem; 

	@include breakpoint-down(mobile) {
		margin-bottom: 8rem; 
	}

	.section__inner {
		display: flex; 
		align-items: center; 
		justify-content: space-between; 

		@include breakpoint-down(tablet) {
			display: block; 
		}
	}

	.section__body {
		flex: 0 0 54%;
		margin-right: 2rem; 

		@include breakpoint-down(tablet) {
			margin-right: 0; 
			margin-top: 3rem; 
			flex: 0 0 100%;
		}
	}
}
