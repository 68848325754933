/* ------------------------------------------------------------ *\
	Tenant Directory
\* ------------------------------------------------------------ */

.tenant-directory {
  padding-bottom: 20rem;

  @include breakpoint-down(mobile) {
    padding-bottom: 12rem;
  }

  .tenant-directory-container {
    max-width: 96rem !important;
  }

  .locations-filter {
    margin-bottom: 6rem;

    @include breakpoint-down(mobile) {
      margin-bottom: 4rem;
    }
  }
}
