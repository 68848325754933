/* ------------------------------------------------------------ *\
	Property
\* ------------------------------------------------------------ */

.property {
  display: flex;
  align-items: flex-start;

  @include breakpoint-down(mobile) {
    display: block;
  }

  .property__image {
    margin-right: 3.5rem;
    border-radius: 1.6rem;
    flex: 0 0 25%;
    position: relative;
    overflow: hidden;

    @include breakpoint-down(mobile) {
      margin-right: 3.4rem;
      float: left;
      max-width: 13.4rem;
      border-radius: 1rem;
    }
  }

  .property__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($c-black, .45);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.33;
    text-align: center;
    text-transform: capitalize;
    color: $c-white;
    text-decoration: none;
    padding: 0 3rem;
    transition: background-color $t-duration $animation;

    &:focus,
    &:hover {
      background-color: rgba($c-black, .3);
    }

    &.prevent-default {
      &:focus,
      &:hover {
        background-color: rgba($c-black, .45);
        cursor: default;
      }
    }
  }

  .property__grid {
    display: flex;

    @include breakpoint-down(mobile) {
      flex-direction: column;
    }
  }

  .property__col {
    width: 15rem;

    @include breakpoint-down(mobile) {
      max-width: 50.8rem;
      width: 100%;
    }
  }

  .property__grid p {
    margin-bottom: 0;
  }

  .property__entry--description {
    p {
      margin-bottom: 0;
    }
  }
}
