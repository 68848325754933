/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
  position: relative;
  min-height: 77.3rem;
  margin-bottom: 5rem;

  @include breakpoint-down(mobile) {
    min-height: 100vh;
  }

  .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hero__image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 15%;
    display: block;
    background: linear-gradient(180deg, black 41%, rgba(185, 185, 185, 0) 84%);
    opacity: .5;
  }

  .hero__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c-black;
    opacity: .2;
  }

  .hero__entry {
    position: relative;
    color: $c-white;
    max-width: 78rem;
    width: 100%;
    position: absolute;
    top: 26.3%;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint-down(small-desktop) {
      padding: 0 2.3rem;
    }

    @include breakpoint-down(mobile) {
      top: 19.5%;
      padding: 0 3.8rem 0 3.9rem;
    }
  }

  .hero__entry--wider {
    max-width: 88rem !important;
  }

  .hero__title,
  .hero__subtitle {
    color: $c-white;
    text-align: center;

    @include breakpoint-down(mobile) {
      text-align: left;
    }
  }

  .hero__subtitle {
    @include breakpoint-down(mobile) {

    }
  }

  .hero__title {
    margin-bottom: 1.7rem;

    @include breakpoint-down(mobile) {
      margin-bottom: 2.2rem;
    }
  }

  .hero__actions {
    position: absolute;
    bottom: -2.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*  hero--alt  */

.hero--alt {
  min-height: 60rem;

  .hero__entry {
    position: relative;
    color: $c-white;
    max-width: 78rem;
    width: 100%;
    position: absolute;
    top: 33.9%;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint-down(small-desktop) {
      padding: 0 2.3rem;
    }

    @include breakpoint-down(mobile) {
      top: 19.5%;
      padding: 0 3.8rem 0 3.9rem;
    }
  }


}


/*  hero--small  */

.hero--small {
  min-height: 55rem;

  .hero__entry {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Hero Standard */

.hero--standard {
  margin-bottom: 5rem;

  .hero__title {
    color: $c-blue;
  }

  .hero__entry {
    @include breakpoint-up(mobile) {
      max-width: 90rem;
      display: block;
      margin: 0 auto;
    }
  }
}

.yam-events-hero {
  .content__inner {
    max-width: 90rem;
    margin: 0 auto;
  }
}