/* ------------------------------------------------------------ *\
	Burger
\* ------------------------------------------------------------ */

.burger {
	display: none; 

	@include breakpoint-down(mobile) {
		display: block; 
	}

	span {
		display: block; 
		width: 3rem; 
		height: .4rem; 
		background-color: $c-white; 
		transition: 
			background-color $t-duration $animation, 
			opacity $t-duration $animation, 
			transform $t-duration $animation; 
	}

	span + span {
		margin-top: .5rem; 
	}
}
