/* ------------------------------------------------------------ *\
	Tooltip
\* ------------------------------------------------------------ */

.tooltip--mobile,
.tooltip {
	transition: opacity $t-duration $animation; 
	opacity: 0; 

	@include breakpoint-down(mobile) {
		opacity: 1; 
	}

	.tooltip-inner {
		max-width: 36.3rem; 
		display: block;
		padding: 3.3rem; 
		background-color: $c-white; 
		border-radius: 1.6rem; 
		box-shadow: 0 0 1.8rem .3rem rgba($c-black, .25);

		@include breakpoint-down(mobile) {
			background-color: transparent; 
			padding: 3rem 0; 
			max-width: 100%; 
			box-shadow: none;
			display: none; 
		}

		@include breakpoint-up(mobile) {
			display: block !important; 
		}
	}

	.tooltip__title {
		text-transform: uppercase; 
		color: $c-blue; 
	}

	.tooltip__address,
	.tooltip__phone {
		margin-bottom: 1.6rem; 
		display: block; 
	}

	.tooltip__social {
		display: block; 
		margin-bottom: 0.7rem; 
		text-decoration: none; 
	}

	.tooltip__link {
		margin-top: 1.3rem; 
		font-weight: 700; 
		text-decoration: none; 
		font-size: 1.5rem;
		color: $c-blue; 
	}
}

.tooltip--mobile {
	display: none; 

	@include breakpoint-down(mobile) {
		display: block; 
	}
}

.tooltip.show {
	opacity: 1; 
}
