/* ------------------------------------------------------------ *\
	Section-slider-date
\* ------------------------------------------------------------ */

.section-slider-date {
	margin-top: 12.3rem; 
	margin-bottom: 11.2rem; 

	@include breakpoint-down(mobile) {
		margin-bottom: 8.6rem; 
	}

	.section__head {
		text-align: center; 
		margin-bottom: 5.8rem;

		@include breakpoint-down(mobile) {
			text-align: left; 
		}
	}

	.section__title {
		display: inline-block; 

		@include breakpoint-down(mobile) {
			display: block; 
		}
	}
}
