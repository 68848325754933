/* ------------------------------------------------------------ *\
	Grid-images
\* ------------------------------------------------------------ */

.grid-images {
	.grid-images__row {
		margin: 0 -1.3rem; 

		@include breakpoint-down(mobile-medium) {
			margin: 0 -.8rem; 
		}
	}

	.grid-images__row--2 {
		margin-top: -7.5rem; 
	}

	.grid-images__col {
		padding: 0 1.3rem; 

		@include breakpoint-down(mobile-medium) {
			padding: 0 .8rem; 
		}
	}

	.grid-images__col img {
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.grid-images__col--1 {
		flex: 0 0 41.6%; 
		padding-bottom: 10.1rem; 

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 100%;
			padding-bottom: 1.7rem; 
		}
	}

	.grid-images__col--2 {
		flex: 0 0 29.2%; 
		position: relative; 

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 50%;
		}

		img {
			position: relative; 
		}

		&:before {
			content: '';
			position: absolute; 
			top: 0; 
			left: -1.3rem; 
			right: -1.3rem; 
			bottom: -2rem; 
			background-color: $c-white; 
			display: block; 

			@include breakpoint-down(mobile-medium) {
				bottom: -1.6rem; 
			}
		}
	}

	.grid-images__col--3 {
		flex: 0 0 29.2%; 
		padding-bottom: 10.1rem; 

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 50%;
			padding-bottom: 9.1rem; 
		}
	}

	.grid-images__col--4 {
		flex: 0 0 31%; 

		@include breakpoint-down(mobile-medium) {
			display: none; 
		}
	}

	.grid-images__col--5 {
		flex: 0 0 69%; 

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 100%;
		}
	}
}

/*  grid-images--alt  */

.grid-images--alt {
	.grid-images__row {
		margin: 0 -1.75rem; 

		@include breakpoint-down(mobile-medium) {
			margin: 0 -.8rem; 
		}
	}

	.grid-images__row + .grid-images__row {
		margin-top: 3.5rem; 

		@include breakpoint-down(mobile-medium) {
			margin-top: 1.6rem; 
		}
	}

	.grid-images__col {
		padding: 0 1.75rem;

		@include breakpoint-down(mobile-medium) {
			padding: 0 .8rem;
		}
	}

	.grid-images__col img {
		width: 100%; 
		height: 100%; 
		object-fit: cover;
	}

	.grid-images__col--1 {
		flex: 0 0 40.2%; 
		padding-bottom: 0; 
	}

	.grid-images__col--2 {
		flex: 0 0 57.3%; 
		position: static; 

		&:before {
			display: none; 
		}
	}

	.grid-images__col--3 {
		flex: 0 0 61.2%; 
		padding-bottom: 0; 
	}

	.grid-images__col--4 {
		flex: 0 0 36.3%; 

		@include breakpoint-down(mobile-medium) {
			display: block; 
		}
	}

	.grid-images__col--5 {
		flex: 0 0 31.4%; 
	}

	.grid-images__col--6 {
		flex: 0 0 66.1%; 
	}
}
