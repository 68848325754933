/* ------------------------------------------------------------ *\
	Link-more
\* ------------------------------------------------------------ */

.link-more {
  font-size: 1.6rem;
  font-weight: 700;
  color: $c-blue;
  text-decoration: none;
  display: inline-block;
  position: relative;

  &:focus svg,
  &:hover svg {
    transform: translateX(1rem);
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -2.24rem;
    height: 1.24rem;
    width: 1.24rem;
    transform: translateY(-50%);
    background-image: url("../images/svg/chevron-right-blue.svg");
    background-position: center;
    background-repeat: no-repeat;
    transition: transform $t-duration $animation;
  }

  svg {
    color: $c-white;
    margin-left: .8rem;
    transition: transform $t-duration $animation;
  }
}

.link-more:hover:after {
  transform: translate(1rem, -50%);
}

/*  link-more--small  */

.link-more--small {
  font-size: 1.4rem;

  svg {
    font-size: 1.2rem;
  }
}

/*  link-more--small-mobile  */

.link-more--small-mobile {
  @include breakpoint-down(mobile) {
    font-size: 1.4rem;
  }

  svg {
    @include breakpoint-down(mobile) {
      font-size: 1.2rem;
    }
  }
}

/*  link-more--alt  */

.link-more--alt {
  color: $c-white;

  &:after {
    background-image: url("../images/svg/chevron-right-white.svg");
  }
}

/*  link-more--capitalize  */

.link-more--capitalize {
  text-transform: capitalize;
}
