/* ------------------------------------------------------------ *\
	Accordion-mobile 
\* ------------------------------------------------------------ */

.accordion-mobile {
  @include breakpoint-up(mobile) {
    column-count: 3;
    column-width: 26.5rem;
    column-gap: 2.5rem;
  }

  .accordion__section.is-active .accordion__icon {
    transform: translateY(-50%) rotate(180deg);
  }

  .accordion__section + .accordion__section {
    margin-top: .1rem;
  }

  .accordion__section {
    &:not(:last-child) {
      @include breakpoint-up(mobile) {
        margin-bottom: 1rem;
      }
    }
  }

  .accordion__icon {
    color: $c-white;
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    right: 2rem;
    transition: transform $t-duration $animation;
    display: none;
    transform: translateY(-50%);

    @include breakpoint-down(mobile) {
      display: block;
    }

    svg {
      vertical-align: middle;
    }
  }

  .accordion__head {
    background-color: $c-blue;
    padding: 1.2rem 2rem;

    @include breakpoint-down(mobile) {
      position: relative;
      padding: 1.2rem 4rem 1.2rem 2rem;
    }
  }

  .accordion__head h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: $c-white;
    letter-spacing: .025em;
    line-height: 1;
    margin-bottom: 0;
  }

  .accordion__body {
    padding: 2.2rem 1.4rem 1.9rem 2rem;

    @include breakpoint-down(mobile) {
      padding: 2.2rem 2rem 1.9rem;
      display: none;
    }

    @include breakpoint-up(mobile) {
      display: block !important;
    }
  }

  .accordion__body ul {
    list-style-type: none;
  }

  .accordion__body li {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -moz-page-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
  }

  .accordion__body li + li {
    margin-top: 1rem;
  }
}
