/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content {
  padding: 5rem 0;

  @include breakpoint-down(mobile) {
    padding: 3rem 0;
  }

  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    margin-bottom: calc(#{$line-height-base}em / 2);
  }

  ul {
    list-style: none;
    margin-bottom: 2.1rem;
  }

  li {
    padding-left: 1.6rem;
    position: relative;
  }

  li + li {
    margin-top: .4rem;
  }

  li:before {
    content: '';
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    background-color: $c-blue;
    position: absolute;
    top: .8rem;
    left: .1rem;
  }

  img {
    @include breakpoint-down(mobile) {
      margin: 0 auto;
      display: block;
    }
  }

  &.text-align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  &.background {
    &-white {
      background-color: $c-white;
    }

    &-grey {
      background-color: $c-blue-light;
    }

    &-boxed {
      .container {
        padding: 3rem 0;
        background-color: $c-blue-light;

        @include breakpoint-down(mobile) {
          padding: 3rem 2rem;
        }
      }
    }

    &-boxed-pattern,
    &-boxed-pattern-reversed {
      .container {
        padding-bottom: 3rem;
      }
    }
  }

  .container {
    position: relative;
  }

  .content__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content__title {
    color: $c-blue;
    margin-bottom: 1.7rem;
  }

  .content__title--inline-block {
    display: inline-block;
  }

  .content__inner {
    position: relative;
  }

  .content__inner--narrow {
    color: $c-grey;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.8;

    @include breakpoint-down(mobile) {
      line-height: 1.5;
    }

    p {
      margin-bottom: 1.6rem;
    }
  }

  .content__actions {
    text-align: left;
  }

  .content__actions--flex {
    display: flex;
    justify-content: center;
  }
}

/*  content--third-two-thirds  */

.content--third-two-thirds {
  &-reverse {
    flex-direction: row-reverse;

    @include breakpoint-down(mobile) {
      flex-direction: column;
    }
  }

  .content__body {
    padding-top: 2.7rem;

    @include breakpoint-down(mobile) {
      padding-top: 1rem;
    }
  }

  .content__inner {
    @include breakpoint-down(mobile) {
      flex-direction: column;
    }
  }

  .content__image {
    @include breakpoint-down(mobile) {
      text-align: center;
    }
  }

  .content__entry {
    margin-top: 1.7rem;
    padding-right: 7rem;
    line-height: 1.86;

    @include breakpoint-down(mobile) {
      padding-right: 0;
      text-align: center;
    }
  }
}

/*  content--alt  */

.content--alt {
  padding: 5.4rem 0 10.6rem;

  .content__title {
    margin-bottom: 2.1rem;
  }

  p {
    max-width: 76.2rem;
    margin: 0 auto;
  }

  .content__image {
    margin: 4.2rem auto 0;
    max-width: 64rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($c-black, .25);
      display: block;
    }
  }

  .content__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*  content--background-image  */

.content--background-image {
  padding: 6.8rem 0 5rem;
  position: relative;
  min-height: 55.4rem;
  display: flex;
  align-items: center;

  .container,
  .content__inner {
    position: initial;
  }

  .content__overlay,
  .content__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content__overlay {
    background-color: rgba($c-black, .55);
  }

  .content__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content__title {
    color: $c-white;
  }

  .content__entry {
    padding-left: 5.4rem;
    position: relative;
    text-align: left;
    color: $c-white;
    max-width: 69.6rem;

    @include breakpoint-down(mobile) {
      padding-left: 3.4rem;
    }

    p {
      margin-bottom: 1.9rem;
    }
  }
}
