/* ------------------------------------------------------------ *\
	Datepicker
\* ------------------------------------------------------------ */

.datepicker {
	color: $c-white; 
	font-size: 1.4rem; 
	text-align: center; 
	text-decoration: none; 

	.datepicker__field {
		caret-color: transparent;
		display: block; 
		font-size: 2.2rem; 
		font-weight: 500; 
		line-height: 1.64; 
		background-color: transparent; 
		border: none; 
		text-align: center; 
		max-width: 23.8rem; 
		width: 100%; 

		@include breakpoint-down(mobile-medium) {
			font-size: 1.8rem; 
		}
	}
}
