/* ------------------------------------------------------------ *\
	Icon-circle
\* ------------------------------------------------------------ */

.icon-circle {
	color: $c-white; 
	border-radius: 50%; 
	width: 11.1rem; 
	height: 11.1rem; 
	border: .1rem solid $c-blue; 
	font-size: 4.2rem; 
	display: flex; 
	align-items: center; 
	justify-content: center; 
	position: relative; 

	svg {
		position: relative; 
	}

	&:before {
		content: '';
		width: 8.5rem; 
		height: 8.5rem; 
		border-radius: 50%; 
		display: block; 
		background-color: $c-blue; 
		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%); 
		transition: 
			height $t-duration $animation, 
			width $t-duration $animation; 
	}

	&:focus:before,
	&:hover:before {
		width: 11.1rem; 
		height: 11.1rem;
	}
}

/*  icon-circle--big  */

.icon-circle--big {
	width: 12rem; 
	height: 12rem; 

	@include breakpoint-down(mobile) {
		width: 6.2rem; 
		height: 6.2rem; 
		font-size: 2.1rem; 
	}

	&:before {
		width: 9.4rem; 
		height: 9.4rem; 

		@include breakpoint-down(mobile) {
			width: 4.8rem; 
			height: 4.8rem; 
		}
	}

	&:focus:before,
	&:hover:before {
		width: 12rem; 
		height: 12rem; 

		@include breakpoint-down(mobile) {
			width: 6.2rem; 
			height: 6.2rem; 
		}
	}
}

/*  icon-circle--small  */

.icon-circle--small {
	width: 7.5rem; 
	height: 7.5rem; 
	font-weight: 300; 
	font-size: 2.7rem; 

	&:before {
		width: 5.8rem; 
		height: 5.8rem; 
	}
	
	&:focus:before,
	&:hover:before {
		width: 7.5rem; 
		height: 7.5rem;
	}
}
