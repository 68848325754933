/* ------------------------------------------------------------ *\
	CTA
\* ------------------------------------------------------------ */

.cta {
  margin-bottom: 10rem;
  background-color: $c-blue-light;
  position: relative;

  @include breakpoint-down(mobile) {
    margin-bottom: 6rem;
  }

  &.cta-reversed {
    .cta-inner {
      flex-direction: row-reverse;

      @include breakpoint-down(mobile) {
        flex-direction: column;
      }
    }
  }

  .cta__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.cta-book {
		background-color: #ffffff;
		margin-bottom: 10px;

		.cta__inner {
			padding: 2.5rem 1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;

			@media (min-width: 700px) {
				padding: 2.5rem 6.8rem;
			}
		}
	
		.flex-inner {
			flex: 1 1 2;
			padding: 1em;

			&.image {
				min-width: 40%;
			}
			
			&.content {
				h2, h3 {
					color: #000000;
					padding: 0;
					line-height: 1.1;
					margin: 0;
					text-transform: uppercase;
				}
				h2 {
					font-size: 2em;
					font-weight: 900;
					letter-spacing: 2px;
				}
				h3 {
					color: #165CAB;
					font-weight: 700;
				}
				p {
					line-height: 2.2rem;
					margin-top: 5px;
				}
				.cta__actions {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: relative;
					gap: 15px;
					flex-direction: column;

					@media (min-width: 700px) {
						flex-direction: row;
					}

					p {
						color: #165CAB;
						font-size: 2rem;
						font-weight: 600;
						flex: 2;
					}
				}
			}
		}
	}

  .cta__inner {
    padding: 6.8rem 2.6rem 7.2rem 15.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include breakpoint-down(small-desktop) {
      padding: 6.8rem 2rem 7.2rem 6rem;
    }

    @include breakpoint-down(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include breakpoint-down(mobile) {
      padding: 5rem 2rem;
    }
  }

  .cta__content {
    max-width: 47.7rem;

    @include breakpoint-down(tablet) {
      max-width: 64rem;
      margin: 0 0 3rem 0;
    }
  }

  .cta__title {
    line-height: 1.25;
    margin-bottom: 2.1rem;
    color: $c-blue;

    @include breakpoint-down(mobile) {
      line-height: 1.54;
      margin-bottom: 1.6rem;
    }
  }

  .cta__aside {
    position: relative;
    max-width: 46.6rem;
    margin-right: 5.5rem;
    width: 100%;

    @include breakpoint-down(tablet) {
      max-width: 64rem;
      margin-right: 0;
    }

    @include breakpoint-down(mobile) {
      max-width: 100%;
    }

    .gform_footer.top_label {
      margin: 0;
      padding: 0;
    }

    .gform_wrapper.gravity-theme .gform_footer input {
      margin: 0;
    }
  }

  .gform_wrapper form {
    align-items: center;
    justify-content: space-between;
  }

  .gform_body.gform-body {
    width: 100%;
    margin-right: 16px;

    @include breakpoint-down(mobile) {
      margin-right: 0;
    }
  }

  .gform_wrapper.gravity-theme input:not([type="submit"]) {
    padding: 1rem 2rem;
  }

  .gform_wrapper.gravity-theme input[type="submit"] {
    padding: 1rem 5rem;
  }
}

/*  cta--boxed  */

.cta--boxed {
  background-color: revert;

  .cta__inner {
    background-color: $c-blue-light;
    padding: 6.8rem 15.3rem 6.5rem 9.5rem;

    @include breakpoint-down(small-desktop) {
      padding: 6.8rem 5rem 7.2rem 6rem;
    }

    @include breakpoint-down(mobile) {
      padding: 5.4rem 3rem 6rem;
    }
  }

  .cta__content {
    max-width: 63.6rem;

    @include breakpoint-down(mobile) {
      max-width: 100%;
    }
  }

  .cta__aside {
    max-width: revert;
    width: revert;

    @include breakpoint-down(mobile) {
      width: 100%;
      max-width: 100%;
    }
  }
}

/*  cta--boxed-alt  */

.cta--boxed-alt {
  background-color: revert;

  .cta__inner {
    background-color: $c-blue-light;
    padding: 8rem 8.5rem 6rem 7.5rem;
    //align-items: revert;

    @include breakpoint-down(small-desktop) {
      padding: 6.8rem 5rem 7.2rem 6rem;
    }

    @include breakpoint-down(mobile) {
      padding: 5rem 2rem;
    }
  }

  .cta__content {
    max-width: 41.6rem;

    @include breakpoint-down(mobile) {
      max-width: 100%;
    }
  }

  .cta__aside {
    max-width: revert;
    width: revert;

    @include breakpoint-down(mobile) {
      max-width: 100%;
      width: 100%;
    }
  }
}

/*  cta--boxed-alt-2  */

.cta--boxed-alt-2 {
  background-color: revert;

  .cta__inner {
    background-color: $c-blue-light;
    padding: 10.5rem 8.5rem 11.2rem 9.5rem;
    //align-items: revert;

    @include breakpoint-down(small-desktop) {
      padding: 6.8rem 5rem 7.2rem 6rem;
    }

    @include breakpoint-down(mobile) {
      padding: 5rem 2rem;
    }
  }

  .cta__body {
    max-width: 66rem;
    position: relative;

    @include breakpoint-down(mobile) {
      max-width: 100%;
    }
  }

  .cta__title {
    font-size: 2.7rem;
    font-weight: 500;
    margin-bottom: 1.7rem;
  }
}

.gform_body.gform-body {
  margin-bottom: 2rem;
}

.gform_footer.top_label,
.gform_button {
  position: relative;

  @include breakpoint-down(mobile) {
    width: 100%;
  }
}

div#gform_confirmation_message_1 {
  background: #fff;
  padding: 2rem;
  border-radius: 1rem 1rem 0 0;
}

img.gform_ajax_spinner {
  position: absolute !important;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}