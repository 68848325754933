/* ------------------------------------------------------------ *\
	Cards
\* ------------------------------------------------------------ */

.cards {
	margin-bottom: 6.9rem; 

	@include breakpoint-down(mobile) {
		margin-top: 5rem; 
	}

	.cards__actions {
		text-align: center; 
	}

	.cards__title {
		text-align: center; 
	}

	.cards__head {
		margin-bottom: 4.6rem; 
	}
}

.cards--alt {
	margin-bottom: 9.7rem; 

	@include breakpoint-down(mobile) {
		margin-bottom: 5rem; 
	}

	.cards__title {
		text-align: left; 
		display: none; 
		margin-bottom: 4rem; 

		@include breakpoint-down(mobile) {
			display: block; 
		}
	}

	.cards__title--display-revert {
		display: revert; 
	}

	.cards__title--center {
		text-align: center; 
		margin: 0 auto 5.7rem; 
		max-width: 70rem; 

		@include breakpoint-down(mobile) {
			max-width: 58rem; 
		}
	}

	.cards__actions {
		margin-top: 5rem; 

		@include breakpoint-down(tablet) {
			margin-top: 3rem; 
		}
	}
}

.cards--full {
	margin-bottom: 11.7rem; 

	@include breakpoint-down(mobile) {
		margin-bottom: 5rem; 
	}
}
