/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
  margin: -5.1rem 0 0;
  padding: 13.1rem 0 3rem;
  background-color: $c-blue;
  color: $c-white;

  @include breakpoint-down(mobile) {
    margin-top: -5.7rem;
    padding: 11.8rem 0 1.8rem;
  }

  .footer__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4.7rem;

    @include breakpoint-down(mobile) {
      margin-bottom: 7rem;
      flex-direction: column;
    }
  }

  .footer__logo {
    @include breakpoint-down(mobile) {
      margin-bottom: 4rem;
    }
  }

  .footer__nav {
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;

      @include breakpoint-down(mobile) {
        flex-direction: column;
      }
    }

    li + li {
      margin-left: 4.5rem;

      @include breakpoint-down(mobile) {
        margin-left: 0;
        margin-top: 1.7rem;
      }
    }

    a {
      text-decoration: none;
      line-height: 1.33;
      font-size: 1.8rem;
      text-transform: capitalize;
      position: relative;
      padding-bottom: .3rem;

      @include breakpoint-down(mobile) {
        font-size: 2.2rem;
      }
    }

    a:focus:after,
    a:hover:after {
      opacity: 1;
    }

    & > ul a:after {
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: .2rem;
      display: block;
      background-color: $c-white;
      pointer-events: none;
      opacity: 0;
      transition: opacity $t-duration $animation;
    }
  }

  .footer__bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content + .footer {
  margin-top: 0;
}
